import { createLogic } from 'redux-logic';
import { stopSubmit } from 'redux-form';
import { mapValues } from 'lodash';
import Fuse from 'fuse.js';
import intl from '../actions/lang/en.json';
import {
  setBannerState,
  getDigitalMediaPending,
  getDigitalMediaSuccess,
  getDigitalMediaError,
  getResourcesPending,
  getResourcesError,
  getDownloadsPending,
  getDownloadsSuccess,
  getDownloadsError,
  getProductsPending,
  setRedirectURL,
  searchDigitalMediaPending,
  searchRedeemedMediaPending,
  setSearchResults,
  setDigitalMediaStatus,
  getResourcesSuccess,
  toggleProcessedItem,
  noResourceLocationsError,
  getMediaContentChildrenPending,
  getMediaContentChildrenSuccess,
  getMediaContentChildrenError,
  queryMediaLocationsPending,
  queryMediaLocationsSuccess,
  queryMediaLocationsError,
  setProductImagesLoading,
} from '../actions/AppActions';
import {
  setTablesIsLoading,
  composeContent,
  dispatchMediaTableSettingsForEach,
} from '../utils/digitalMediaHelpers';
import { ENABLE_DIGITAL_MEDIA_REACT } from '../constants';

const getMembershipItems = client =>
  client
    .get('/membership-items')
    .then(res => res?.data?.items || [])
    .catch(err =>
      err?.response?.status === 404
        ? []
        : Promise.reject(err)
    );

const getSeats = client =>
  client
    .get('/seats')
    .then(res => res?.data?.seats || [])
    .catch(err =>
      err?.response?.status === 404
        ? []
        : Promise.reject(err)
    );

export const getMediaContentChildren = createLogic({
  type: getMediaContentChildrenPending().type,
  process({ httpClient, action }, dispatch, done) {
    const itemNumber = action.payload;
    const client = httpClient(dispatch);
    return client
      .get(`/products/${itemNumber}/children`)
      .then(res => {
        const sortedChildItems = res.data.childrenItems.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
        dispatch(getMediaContentChildrenSuccess({
          parentItemNumber: res.data.itemNumber,
          sortedChildItems
        }));
      })
      .catch(err => {
        if (err?.response?.status === 404) dispatch(getMediaContentChildrenSuccess({ parentItemNumber: itemNumber }));
        else dispatch(getMediaContentChildrenError({err, parentItemNumber: itemNumber}));
      })
      .finally(() => done());
  }
});

export const getDigitalMediaLogic = createLogic({
  type: getDigitalMediaPending().type,
  latest: true,
  process({ httpClient }, dispatch, done) {
    const client = httpClient(dispatch);
    setTablesIsLoading(dispatch, true);
    return Promise.all([getSeats(client), getMembershipItems(client)])
      .then(([seats, membershipItems]) => {
        const hasData = !!seats?.length || !!membershipItems?.length;
        if (!hasData) {
          dispatch(setBannerState({
            data: {
              message: intl.Errors.DigitalMedia.No_Digital_Media_Message
            }
          }));
        }
        const memberships = membershipItems.map(membership => ({ ...membership, distributionAction: 'MEMBERSHIP' }));
        memberships.sort((a,b) => b.orderDate - a.orderDate);
        const content = [...seats, ...memberships];
        const tableData = { tables: composeContent(content), hasData };
        const videoContent = seats.filter(item => item.distributionChannelCodes.includes('VLC'));
        return Promise.all([tableData, ...videoContent.map(item => dispatch(getMediaContentChildrenPending(item.itemNumber)))]);
      })
      .then(([{ tables, hasData }]) => {
        setTablesIsLoading(dispatch, false);
        dispatch(getDigitalMediaSuccess({ data: { ...tables, hasData } }));
        dispatch(setDigitalMediaStatus(true));
        Object.keys(tables).forEach(key => {
          const itemNumbers = tables?.[key]?.rows
            ?.reduce((acc, cur) => [
              ...(acc || []),
              cur?.itemNumber,
            ], []);
          if (itemNumbers?.length) {
            dispatch(getProductsPending({
              type: key,
              itemNumbers,
            }));
            dispatch(setProductImagesLoading({ type: key }));
          }
        });
        done();
      })
      .catch(err => {
        dispatch(setBannerState({
          data: {
            type: 'error',
            message: intl.Errors.DigitalMedia.Get_Digital_Media_Error,
          }
        }));
        dispatch(getDigitalMediaError(err));
        done();
      });
  }
});

const getResourceURLs = ({ httpClient, dispatch, itemNumber }) =>
  httpClient(dispatch)
    .get(`/resources/${itemNumber}/locations`)
    .then(res => {
      const locations = res?.data?.locations;
      return locations?.length
        ? locations
        : Promise.reject({ data: locations });
    })
    .then(locations => {
      dispatch(getResourcesSuccess({ data: locations }));
      return locations;
    })
    .catch(err => {
      dispatch(noResourceLocationsError(err));
      return Promise.reject(err);
    });

export const getResourcesAndRedirectLogic = createLogic({
  type: getResourcesPending().type,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    getResourceURLs({ httpClient, dispatch, itemNumber: action.payload })
      .then(locations => {
        dispatch(setRedirectURL({ data: locations?.[0]?.url }));
        done();
      })
      .catch(err => {
        const bannerState = {
          data: {
            type: 'error',
            message: intl.Errors.DigitalMedia.Get_Resources_Error,
          }
        };
        dispatch(setBannerState(bannerState));
        dispatch(getResourcesError(err));
        done();
      });
  }
});

export const getDownloadURLsLogic = createLogic({
  type: getDownloadsPending().type,
  latest: true,
  process({ httpClient, action }, dispatch, done, browserWindow = window) {
    const { payload: itemNumber, meta } = action;
    const { onError, onSuccess, onMultiDownload } = meta;

    dispatch(toggleProcessedItem('gettingDownloadLinks'));
    getResourceURLs({ httpClient, dispatch, itemNumber })
      .then(locations => {
        if (locations?.length === 1) {
          const url = locations[0]?.url;
          // trigger browser download
          // eslint-disable-next-line no-param-reassign
          browserWindow.location = url;
          dispatch(toggleProcessedItem());
        } else {
          dispatch(getDownloadsSuccess({
            data: {
              totalRows: locations?.length,
              rows: locations
            }
          }));
          onMultiDownload?.();
        }
        onSuccess?.(locations);
        done();
      })
      .catch(err => {
        onError?.(intl.Errors.DigitalMedia.Get_Resources_Error);
        dispatch(getDownloadsError(err));
        done();
      });
  }
});

export const searchLogic = createLogic({
  type: searchDigitalMediaPending().type,
  latest: true,

  validate({ action }, allow, reject) {
    if (action.payload.q) {
      allow(action);
    } else {
      reject(stopSubmit(action.payload.formName, {}));
    }
  },
  process({ action: { payload: { mediaType, q: query, sortedContent } }, getState }, dispatch, done) {
    const digitalMedia = ENABLE_DIGITAL_MEDIA_REACT ? sortedContent : getState().app.get('digitalMedia').toJS();
    const maxPatternLength = 30;
    const options = {
      shouldSort: true,
      threshold: 0.2,
      location: 30,
      distance: 400,
      maxPatternLength,
      keys: [
        'itemName',
        'orderNumber'
      ]
    };
    const searchRows = (rows, q) => (new Fuse(rows, options)).search(q);

    // If query is longer than maxPatternLength an error will be thrown;
    const trimmedQuery = query.substr(0, maxPatternLength);

    dispatchMediaTableSettingsForEach(dispatch, { currentPage: 1, isLoading: true });

    // use destructuring to remove un-searchable key from DigitalMedia;
    // eslint-disable-next-line no-unused-vars
    const { hasData, ...searchableData } = digitalMedia;

    const filteredDigitalMedia = mapValues(searchableData, value => {

      const results = searchRows(value.rows, trimmedQuery);
      const length = results.length;

      return {
        rows: results,
        totalRows: length,
        searchStatus: length || 'empty'
      };
    });

    dispatchMediaTableSettingsForEach(dispatch, { isLoading: false });
    dispatch(setSearchResults(filteredDigitalMedia));
    done();
  }
});

export const redeemCodeLogic = createLogic({
  type: searchRedeemedMediaPending().type,
  latest: true,

  validate({ action }, allow, reject) {
    if (action.payload.codes) {
      allow(action);
    } else {
      reject();
    }
  },

  process({ action: { payload: { codes }, meta: { onError, onSuccess } }, getState }, dispatch, done) {
    const queries = codes
      .map(code => code.items.map(item => item.substr(0, maxPatternLength)))
      .reduce((x, y) => x.concat(y), []);
    const digitalMedia = getState().app.get('digitalMedia').toJS();
    const maxPatternLength = 30;
    const options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 400,
      maxPatternLength,
      keys: [
        'itemNumber'
      ]
    };
    const searchRows = (rows, q) => (new Fuse(rows, options)).search(q);

    dispatchMediaTableSettingsForEach(dispatch, { currentPage: 1, isLoading: true });

    // use destructuring to remove un-searchable key from DigitalMedia;
    // eslint-disable-next-line no-unused-vars
    const { hasData, ...searchableData } = digitalMedia;

    const filteredDigitalMedia = mapValues(searchableData, value => {
      const results = queries.map(query => searchRows(value.rows, query)).reduce((x, y) => x.concat(y), []);
      const length = results.length;

      return {
        rows: results,
        totalRows: length,
        searchStatus: length || 'empty'
      };
    });

    const codesFound = Object.keys(filteredDigitalMedia)
      .map(key => filteredDigitalMedia[key])
      .map(contentType => contentType.totalRows)
      .find(totalRows => totalRows > 0);

    if (codesFound) {
      dispatchMediaTableSettingsForEach(dispatch, { isLoading: false });
      dispatch(setSearchResults(filteredDigitalMedia));
      onSuccess?.();
    } else {
      onError?.(intl.Errors.DigitalMedia.No_Redeemed_Content_Found);
    }
    done();
  }
});

export const queryMediaLocations = createLogic({
  type: queryMediaLocationsPending().type,
  process({ httpClient, action }, dispatch, done) {
    const contentChildren = action?.payload?.contentChildren;
    const parentItemNumber = action?.payload?.item?.itemNumber;
    const setRetrieveVideos = action?.payload?.setRetrieveVideos;
    httpClient(dispatch).post('/query-media-locations', contentChildren.map(c => c.itemNumber))
      .then(res => {
        const childrenWithUrl = res.data.map((item) => ({
          url: item.urls[0].url,
          ...contentChildren.find(c => c.itemNumber === item.itemNumber)
        }));

        dispatch(queryMediaLocationsSuccess({ parentItemNumber, contentChildren: childrenWithUrl }));
        setRetrieveVideos('complete');
      })
      .catch(err => {
        dispatch(queryMediaLocationsError(err));
        setRetrieveVideos('error');
      })
      .finally(() => done());
  }
});

export default [
  getDigitalMediaLogic,
  getResourcesAndRedirectLogic,
  getDownloadURLsLogic,
  searchLogic,
  redeemCodeLogic,
  getMediaContentChildren,
  queryMediaLocations
];
