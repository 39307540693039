import { createLogic } from 'redux-logic';
import { stopSubmit } from 'redux-form';
import {
  FORGOT_PIN_REQUEST_PENDING,
  NEW_PIN_REQUEST_PENDING,
  PIN_MANAGEMENT_REQUEST,
  PIN_FORM_TYPES,
  MODALS
} from '../constants';
import { forgotPinRequestPending, newPinRequestPending, toggleModal } from '../actions/AppActions';
import { setBannerState } from '../actions/AppActions';
import intl from '../actions/lang/en.json';

export const transformPinRequestLogic = createLogic({
  type: PIN_MANAGEMENT_REQUEST,
  latest: true,

  transform({ action }, next) {
    switch (action.payload.formType){
      case PIN_FORM_TYPES.FORGOT:
            next(forgotPinRequestPending(action.payload));
            break;
      case PIN_FORM_TYPES.NEW:
            next(newPinRequestPending(action.payload));
            break;
    }
  }
});

export const forgotPinLogic = createLogic({
  type: FORGOT_PIN_REQUEST_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done ) {
    const {accountNumber} = action.payload;

    const {emailAddress, firstName, lastName} = getState().app.get('currentUser').toJS();

    return httpClient(dispatch).post(`/billing-accounts/${accountNumber}/recreate-pin`, {
      accountNumber,
      userEmail: emailAddress,
      firstName,
      lastName
    })
    .then(() => {
      const bannerState = {
        data: { type: 'success', message: intl.Errors.PIN.Forgot_PIN_success, location: MODALS.PIN_MANAGEMENT_MODAL }
      };
      dispatch(toggleModal({show: MODALS.PIN_MANAGEMENT_MODAL}));
      dispatch(setBannerState(bannerState));
      dispatch(stopSubmit('ForgotPin'));
      done();
    })
    .catch((status) => {
      let bannerState;
      if(status === 404) {
        bannerState = {
          data: { type: 'error', message: intl.Errors.PIN.Forgot_PIN_invalid, location: MODALS.PIN_MANAGEMENT_MODAL }
        };
      } else {
        bannerState = {
          data: { type: 'error', message: intl.Errors.PIN.Forgot_PIN_failure, location: MODALS.PIN_MANAGEMENT_MODAL }
        };
      }
      dispatch(setBannerState(bannerState));
      dispatch(stopSubmit('ForgotPin'));
      done();
    });
  }
});

export const requestNewPinLogic = createLogic({
  type: NEW_PIN_REQUEST_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const {accountNumber, accountEmail, accountName} = action.payload;

    const {emailAddress, firstName, lastName} = getState().app.get('currentUser').toJS();

    return httpClient(dispatch).post(`/billing-accounts/${accountNumber}/request-pin`, {
      accountNumber,
      accountEmail,
      accountName,
      userEmail: emailAddress,
      firstName,
      lastName
    })
      .then(resp => {
        const bannerState = {
          data: { type: 'success', message: intl.Errors.PIN.New_PIN_success, location: MODALS.PIN_MANAGEMENT_MODAL }
        };
        dispatch(toggleModal({show: MODALS.PIN_MANAGEMENT_MODAL}));
        dispatch(setBannerState(bannerState));
        dispatch(stopSubmit('ForgotPin'));
        done();
      })
      .catch(error => {
        const bannerState = {
          data: { type: 'error', message: intl.Errors.PIN.New_PIN_failure, location: MODALS.PIN_MANAGEMENT_MODAL }
        };
        dispatch(setBannerState(bannerState));
        dispatch(stopSubmit('ForgotPin'));
        done();
      });
  }
});

export default [
  transformPinRequestLogic,
  requestNewPinLogic,
  forgotPinLogic
];
