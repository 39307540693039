import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ClearIcon from '../../../img/sprite/clear.svg';
import AccessibleText from './AccessibleText';

const StyledClearButton = styled.button`
  ${props => props.styles};
  background-color: transparent;
  border: none;
  margin-bottom: auto;
  padding: 0;
  overflow: hidden;
  width: 20px;
  ${({ absolute }) => absolute && 'position: absolute; right: 0; top: 0;'}

  &:focus {
    outline: none;
  }
`;

const StyledSvg = styled.svg`
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  color: #bbb;
`;

const ClearButton = props => {
  const { onClick, className, accessibleLabel, absolute } = props;
  const attrs = {
    button: {
      type: 'button',
      'data-qa-hook': props['data-qa-hook'],
      onClick,
      absolute
    }
  };
  return (
    <StyledClearButton {...attrs.button} className={className}>
      {accessibleLabel
        ? <AccessibleText>{accessibleLabel}</AccessibleText>
        : null
      }
      <StyledSvg><use xlinkHref={`#${ClearIcon.id}`} /></StyledSvg>
    </StyledClearButton>
  );
};

const { string, func } = PropTypes;

ClearButton.propTypes = {
  'data-qa-hook': string,
  onClick: func,
  className: string,
  accessibleLabel: string
};

export default ClearButton;

