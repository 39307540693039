import React from 'react';
import { RedeemButton } from './RedeemButton';
import DownChevron from '../../../img/sprite/down-chevron.svg';
import BlueChevron from '../../../img/sprite/left-chevron-blue.svg';
import Button from './Button';
import styled from 'styled-components';
import { StyledSvg } from './SideNavIndividual';
import SignOutIcon from '../../../img/sprite/sign-out-icon.svg';
import css from '../../../css/css-variables';
import { StyledChevron } from './SideNavOrg';

const StyledButton = styled.button`
  font-size: 14px;
  color: ${css.colors.Progress};
  text-decoration: none;

  &:hover {
    ${({noHover}) => noHover ? '' : 'text-decoration: underline'};
  }
`;

const OrgNameButton = styled.button`
  text-align: left;
  width: 100%;
  padding: 16px 0;
  margin: 0;
  font-size: 16px;
  color: ${css.colors.Progress};
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: fit-content; 
`;

export const SignOutText = styled.p`
  font-size: 12px;
  color: ${css.colors.Progress};
  pointer-events: auto;

  :hover {
    text-decoration: underline;
  }
`;

function SideNavSelect ({
  setView,
  loading,
  showMoreOrgs,
  firstThreeOrgs,
  handleSelectOrg,
  setViewAccountsModal,
  linkedOrganizations,
  onLogOut
}) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', borderBottom: 'solid 1px #e0e0e0' }}>
        <Button
          aria-label="My Account"
          type='button'
          style={{
            textAlign: 'left',
            width: '100%',
            padding: '16px 0',
            background: 'inherit',
            margin: '0',
            fontSize: '16px',
            color: css.colors.Progress,
            textDecoration: 'none',
            fontWeight: '800',
            fontStyle: 'normal'
          }} 
          onClick={() => {setView('INDIVIDUAL')}}
        >
          My Account
        </Button>
        <StyledChevron transform="rotate(180deg)"><use href={`#${BlueChevron.id}`} xlinkHref={`#${BlueChevron.id}`} /></StyledChevron>
      </div>
      {loading && <div style={{ color: css.colors.Progress, paddingTop: '8px' }}>Loading...</div>}
      {!loading && firstThreeOrgs && firstThreeOrgs?.map((org, index) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            borderBottom: 'solid 1px #e0e0e0'
          }}
          key={index}
        >
          <OrgNameButton
            type="button"
            role="button"
            aria-label="Select Org"
            onClick={() => handleSelectOrg(org)}
          >
            {org?.organizationName}
          </OrgNameButton>
          <StyledChevron transform="rotate(180deg)"><use href={`#${BlueChevron.id}`} xlinkHref={`#${BlueChevron.id}`} /></StyledChevron>
        </div>
      ))}
      {showMoreOrgs && (
        <StyledButton
          type="button"
          onClick={() => setViewAccountsModal()}
          style={{ margin: '14px 14px 0px 14px' }}
          noHover={true}
        >
          View More Accounts
        </StyledButton>
      )}
      <ButtonContainer>
        <RedeemButton isSideNav={true} style={{ fontSize: '14px', marginTop: '14px' }} />
        <button data-tracking-component="button" data-tracking-id="nav-sign-out-btn" data-qa-hook="nav-sign-out-btn" type="button" onClick={onLogOut} style={{ fontSize: '14px', color: css.colors.Progress, background: 'transparent', display: 'flex', gap: '4px' }}>
          <StyledSvg marginRight="0" width="18px" height="18px"> 
            <use href={`#${SignOutIcon.id}`} />
          </StyledSvg>
          <SignOutText>Sign Out</SignOutText>
        </button>
      </ButtonContainer>
    </>
  );
}

export default SideNavSelect;