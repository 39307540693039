import Modal from '../../Modal';
import React from 'react';
import { bool, func, object } from 'prop-types';
import styled from 'styled-components';
import ClearButton from '../../ClearButton';
import {
  SearchBar,
  SearchBarInput,
} from '@lifewayit/components/dist/lib';
import { useState } from 'react';
import {
  ORG_PATHS
} from '../../../constants';
import { debounce } from 'lodash';
import css from '../../../../../css/css-variables';
import { NavLink } from 'react-router-dom';

const ViewAccountsModal = ({
  show,
  onClose,
  linkedOrganizations
}) => {

  const [filteredAccs, setFilteredAccs] = useState(linkedOrganizations);

  const handleClose = () => {
    onClose?.();
  };

  const debouncedHandleChange = debounce((value) => {
    const searchValue = value.trim();
    if(searchValue) {
      const filteredOrgs = linkedOrganizations?.filter(org =>
        org.organizationName.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredAccs(filteredOrgs);
    } else {
      setFilteredAccs(linkedOrganizations);
    }
  }, 500);

  const handleChange = (e) => {
    debouncedHandleChange(e.target.value);
  };

  return (
    <Modal show={show} toggle={handleClose} closeOnClick={true} width="26rem" fullScreenOnMobile={false}>
      <Container>
        <Heading>Find Org Account</Heading>
        <CloseButton onClick={handleClose} />
        <div>
        <SearchBar
          onSelect={(value) => {
              submitSearch(value, 'true');
            }}
          className="px-5"
          style={{borderRadius: '12px 12px 0 0', paddingTop: '14px', paddingBottom: '14px', background: css.colors.Form}}
        >
            <SearchBarInput
              placeholder="Search by name"
              aria-label="Search by name"
              onChange={handleChange}
              buttonProps={{ 'aria-label': 'Search by name' }}
            />
        </SearchBar>
          <div style={{
            borderRadius: '0 0 12px 12px',
            overflow: 'scroll',
            overflowX: 'hidden',
            border: '1px solid #eeeeee',
            maxHeight: '300px',
            height: '100%',
            textAlign: 'left',
          }}
          >
            <ul style={{ padding: '0px 21px', height: '367px', textDecoration: 'none' }}>
              {filteredAccs?.map((org, index) => (
                <StyledLi
                  key={index}
                  style={{ borderBottom: index === filteredAccs.length - 1 ? 'none' : '' }}
                >
                  <StyledNavLink
                    style={{textDecoration: 'none'}}
                    to={ORG_PATHS.DASHBOARD.replace(':organizationId', org.organizationId)}
                    onClick={() => handleClose()}
                  >
                    {org.organizationName}
                  </StyledNavLink>
                </StyledLi>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

ViewAccountsModal.propTypes = {
  show: bool,
  onClose: func,
  linkedOrganizations: object
};

export default ViewAccountsModal;

const Container = styled.aside`
  background-color: white;
  width: 100%;
  padding: 24px;
  font-size: 1rem;
  position: relative;
  height: auto;
`;

const StyledNavLink = styled(NavLink)`
  padding: 16px;
  font-weight: 800;
  color: ${css.colors.Progress}
`;

const StyledLi = styled.li`
  border-bottom: solid 1px #e0e0e0;

  &:last-child a {
    border-bottom: none;
  }
`;

const Heading = styled.h3`
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: bold
`;

const CloseButton = styled(ClearButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
