import { createLogic } from 'redux-logic';
import { stopSubmit } from 'redux-form';
import {
  PROFILE_UPDATE_ACCOUNT_PENDING,
  LIFEWAY_ACCOUNTS_URL,
  PATH_PROFILE,
  GET_VOD_DEVICES_PENDING,
  RENAME_VOD_DEVICE_PENDING,
  REMOVE_VOD_DEVICE_PENDING,
  REMOVE_DEVICE_CONFIRM,
  GET_WEBAUTHN_PUBLIC_KEYS_PENDING,
  RENAME_WEBAUTHN_PUBLIC_KEY_PENDING,
  REMOVE_WEBAUTHN_PUBLIC_KEY_CONFIRM,
  REMOVE_WEBAUTHN_PUBLIC_KEY_PENDING
} from '../constants';
import {
  updateEmailSuccess,
  updateEmailError,
  updatePasswordSuccess,
  updatePasswordError,
  setBannerState,
  getVODDevicesSuccess,
  getVODDevicesError,
  renameVODDeviceSuccess,
  renameVODDeviceError,
  removeVODDevicePending,
  removeVODDeviceSuccess,
  removeVODDeviceError,
  toggleModal,
  triggerModal,
  getWebAuthnPublicKeysSuccess,
  renameWebAuthnPublicKeySuccess,
  renameWebAuthnPublicKeyError,
  removeWebAuthnPublicKeyPending,
  removeWebAuthnPublicKeySuccess,
  removeWebAuthnPublicKeyError,
  getWebAuthnPublicKeysError
} from '../actions/AppActions';
import history from '../utils/history';

export const updateAccountLogic = createLogic({
  type: PROFILE_UPDATE_ACCOUNT_PENDING,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const state = getState();
    const frm = state.form[action.payload.form];
    const isEmail = action.payload.form === 'emailForm';
    // no change was made to the account information
    if (
      (isEmail && frm.initial.email === action.payload.email) ||
      (!isEmail && frm.initial.password === action.payload.password)
    ) {
      reject(stopSubmit(action.payload.form, {}));
    } else {
      allow(action);
    }
  },

  process({ httpClient, action, getState }, dispatch, done) {
    const http = httpClient(dispatch);
    let isPassword;
    const requests = [];
    const state = getState();
    const frm = state.form[action.payload.form];
    const isEmail = action.payload.form === 'emailForm';
    const intl = state.intl.messages;

    if (isEmail && action.payload.email !== frm.initial.email) {
      const emailData = { newEmail: action.payload.email };
      const updateEmail = http.put(
        `${LIFEWAY_ACCOUNTS_URL}/api/user/profile/updateUsername`,
        emailData
      );
      requests.push(updateEmail);
    }
    if (!isEmail && action.payload.password !== 'password') {
      const passwordData = { password: action.payload.password };
      const updatePassword = http.put(
        `${LIFEWAY_ACCOUNTS_URL}/api/user/profile/updatePassword`,
        passwordData
      );
      requests.push(updatePassword);
    }

    return Promise.all(requests)
      .then(values => {
        values.forEach(resp => {
          isPassword = resp?.config.url.toLowerCase().indexOf('password') > -1;

          if (isPassword) {
            // updated password
            dispatch(updatePasswordSuccess());
          } else {
            // update email
            dispatch(updateEmailSuccess(action.payload.email));
          }
        });
        // stop form submission
        dispatch(stopSubmit(action.payload.form));
        // close the form
        history.push(PATH_PROFILE);

        const bannerState = {
          data: {
            type: 'success',
            message:
              intl.Success.Account[
                isEmail ? 'Update_Email_Success' : 'Update_Password_Success'
              ]
          }
        };
        dispatch(setBannerState(bannerState));

        return values;
      })
      .catch(({ response }) => {
        isPassword =
          response?.config?.url.toLowerCase().indexOf('password') > -1;
        const errors = { email: null, password: null };
        const error = {};

        const errType = isPassword ? 'password' : 'email';
        errors[status === 504 ? 'timeout' : errType] = response;

        if (errors.password) {
          error.password = true;
          dispatch(updatePasswordError(errors.password));
        }

        if (errors.email) {
          error.email = true;
          if (
            errors.email.data.code ===
            'lifewayaccount.usernamealreadyexists.error'
          ) {
            error.email = intl.Errors.Account.Username_Exists;
          }
          dispatch(updateEmailError(errors.email));
        }

        if ((errors.password && errors.email) || errors.timeout) {
          error._error = intl.Errors.Account.Update_Failed;
        } else {
          isPassword = Boolean(errors.password);
          const errType = errors[isPassword ? 'password' : 'email'];

          // gateway errors
          if (errType && errType.status >= 500)
            error._errors =
              intl.Errors.Account[
                `Update_${isPassword ? 'Password' : 'Email'}_Error`
              ];
        }

        dispatch(stopSubmit(action.payload.form, error));

        return errors;
      })
      .finally(() => done());
  }
});

export const getDevicesLogic = createLogic({
  type: GET_VOD_DEVICES_PENDING,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient(dispatch)
      .get(`${LIFEWAY_ACCOUNTS_URL}/device/list`)
      .then(res => {
        dispatch(getVODDevicesSuccess(res.data));
      })
      .catch(err => {
        dispatch(getVODDevicesError(err));
      })
      .finally(_ => done());
  }
});

export const getWebAuthnPublicKeysLogic = createLogic({
  type: GET_WEBAUTHN_PUBLIC_KEYS_PENDING,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient(dispatch)
      .get(`${LIFEWAY_ACCOUNTS_URL}/api/webauthn/listPublicKeys`)
      .then(res => {
        dispatch(getWebAuthnPublicKeysSuccess(res.data));
      })
      .catch(err => {
        dispatch(getWebAuthnPublicKeysError(err));
      })
      .finally(() => {
        done();
      });
  }
});

export const renameDeviceLogic = createLogic({
  type: RENAME_VOD_DEVICE_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { deviceCode, deviceName } = action.payload;
    const { onSuccess, onError } = action.meta;

    httpClient(dispatch)
      .put(`${LIFEWAY_ACCOUNTS_URL}/device/name`, {
        deviceCode,
        deviceName
      })
      .then(() => {
        dispatch(renameVODDeviceSuccess({ deviceCode, deviceName }));
        onSuccess && onSuccess();
      })
      .catch(err => {
        dispatch(renameVODDeviceError(err));
        onError && onError();
      })
      .finally(_ => done());
  }
});

export const renameWebAuthnPublicKeyLogic = createLogic({
  type: RENAME_WEBAUTHN_PUBLIC_KEY_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { id, name, onSuccess, onError } = action.payload;

    httpClient(dispatch)
      .put(`${LIFEWAY_ACCOUNTS_URL}/api/webauthn/updatePublicKeyFriendlyName`, {
        publicKeyId: id,
        name
      })
      .then(() => {
        dispatch(renameWebAuthnPublicKeySuccess({ id, name }));
        onSuccess?.();
      })
      .catch(err => {
        dispatch(renameWebAuthnPublicKeyError(err));
        onError?.();
      })
      .finally(() => {
        done();
      });
  }
});

export const removeDeviceConfirmLogic = createLogic({
  type: REMOVE_DEVICE_CONFIRM,
  latest: true,
  process({ action }, dispatch, done) {
    const { deviceCode, deviceName } = action.payload;
    const modalProps = {
      show: true,
      message: `Are you sure you want to log out of "${deviceName}"?`,
      okLabel: 'Yes',
      cancelLabel: 'No',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(removeVODDevicePending({ deviceCode }, action.meta));
        dispatch(toggleModal());
        done();
      },
      cancelClick: () => {
        dispatch(toggleModal());
        done();
      }
    };
    dispatch(triggerModal(modalProps));
  }
});

export const removeWebAuthnPublicKeyConfirmLogic = createLogic({
  type: REMOVE_WEBAUTHN_PUBLIC_KEY_CONFIRM,
  latest: true,
  process({ action }, dispatch, done) {
    const { name } = action.payload;
    const modalProps = {
      show: true,
      message: `Removing this device will forget your passkey. Are you sure you want to remove "${name}"?`,
      okLabel: 'Yes',
      cancelLabel: 'No',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(removeWebAuthnPublicKeyPending(action.payload));
        dispatch(toggleModal());
        done();
      },
      cancelClick: () => {
        dispatch(toggleModal());
        done();
      }
    };
    dispatch(triggerModal(modalProps));
  }
});

export const removeWebAuthnPublicKeyLogic = createLogic({
  type: REMOVE_WEBAUTHN_PUBLIC_KEY_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { id, onError } = action.payload;

    httpClient(dispatch)
      .delete(`${LIFEWAY_ACCOUNTS_URL}/api/webauthn/removePublicKey/${id}`)
      .then(() => {
        dispatch(removeWebAuthnPublicKeySuccess({ id }));
      })
      .catch(err => {
        onError?.();
        dispatch(removeWebAuthnPublicKeyError(err));
      })
      .finally(() => done());
  }
});

export const removeDeviceLogic = createLogic({
  type: REMOVE_VOD_DEVICE_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { deviceCode } = action.payload;
    const { onSuccess, onError } = action.meta;

    httpClient(dispatch)
      .delete(`${LIFEWAY_ACCOUNTS_URL}/device/${deviceCode}`)
      .then(() => {
        onSuccess && onSuccess();
        dispatch(removeVODDeviceSuccess({ deviceCode }));
      })
      .catch(err => {
        onError && onError();
        dispatch(removeVODDeviceError(err));
      })
      .finally(_ => done());
  }
});

export default [
  updateAccountLogic,
  getDevicesLogic,
  renameDeviceLogic,
  removeDeviceConfirmLogic,
  removeDeviceLogic,
  getWebAuthnPublicKeysLogic,
  renameWebAuthnPublicKeyLogic,
  removeWebAuthnPublicKeyConfirmLogic,
  removeWebAuthnPublicKeyLogic
];
