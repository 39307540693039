import { ENABLE_REBRANDING_PHASE_1 } from '../js/app/constants';

const baseStyles = {
    'bdrs': '.1875em',
    'gutterx2': '2.5em',
    'gutter': '1.875em',
    'gutter2': '1.375em',
    'gutter3': '0.938em',
    'gutter4': '.3125em',
    'gutter8': '.15625em',
    'labelMb':  '0.313em',
    'mobileHitBox': '2.75em',
    'notifyBorder': '.15em',
    'headerMargin': '.2em',
    'bdRadius': '4px',
    'fontSize': {
      'smallest': '.75em',
      'small': '.875em',
      'medium': '1em',
      'large': '1.125em',
      'larger': '1.25em',
      'largest': '1.5em'
    },
    'padding': {
      'small': '1rem'
    },
    'gap': {
      'smallest': '0.5rem'
    },
    'borderRadius': '0.75rem',
    'colors': {
      'Body': '#222',
      'LightBlack': '#5B5B64',
      'Background': '#FCFCFC',
      'Muted': '#808080',
      'AccentGray': '#e0e0e0',
      'LightGray': '#efefee',
      'HoverGray': '#eeeeee',
      'ChromeGray' : '#a9a9a9',
      'BoxShadowGray' : '#e3e3e3',
      'DarkGray': '#808080',
      'SmokeGray': '#646464',
      'Primary': '#28599b',
      'PrimaryLight': '#e5ecf5',
      'AccentLight': '#fafafa',
      'AccentMedium': '#e7eef6',
      'PrimaryHover': '#0062a1',
      'Error': '#a62a00',
      'Delete': '#c33f3d',
      'Notification': '#d9edf7',
      'NotificationText': '#225d99',
      'InfoNotification': '#B9E3FE',
      'InfoNotificationText': '#0076C0',
      'StrongsNumber': '#008042',
      'BuyAgain': '#37993F',
      'Hover': 'rgba(17, 17, 17, 0.05)',
      'DarkHover': 'rgba(17, 17, 17, 0.10)',
      'ActiveMenuItem': '#16395f',
      'Border': '#dcdcdc',
      'BorderLight': '#ededed',
      'BorderGray': '#8F8F8F',
      'Button': '#dddddd',
      'ButtonRed': '#a6272f',
      'Form': '#d9edf7',
      'FormText': '#225d99',
      'FormBorder': '#cccccc',
      'Trash': '#A5A29C',
      'ModalWarning': '#ecd5d7',
      'ModalError': '#ae2c2e',
      'ErrorMessage': '#b54a42',
      'Warning': '#F8F89C',
      'WarningDark': '#e2b222',
      'ErrorBright': '#f61302',
      'BlueMessage': '#28599B',
      'TableHeader': '#EBEBEB',
      'TableRowBorder': '#F2F2F2',
      'TableHeaderHover': '#5c5c5c',
      'TableResizeBorder': '#999',
      'TableRowHover': '#eaeaea',
      'TableOddRow': '#f7f7f9',
      'TableSortOrder': '#ccc',
      'NavText': '#000000',
      'DarkBorderHover' : '#A0A0A1',
      'CardLineText': '#676668',
      'BadgeBackground': '#eee',
      ButtonPrimary: '#264989',
      ButtonTextPrimary: '#FFF',
      LifewayComIconGray: '#878787',
      LifewayComTextGray: '#747474',
      LifewayComDecorationGray: '#bbbbbb',
      Meter: '#e4e4e4',
      MeterRed: '#f24236',
      MeterYellow: '#ffa630',
      MeterGreen: '#7b9f34',
      RedeemActive: '#476692',
      ScrollActive: '#7d7d7d',
    },
    'z': {
      'Hidden': -1,
      'Nav': 1000,
      'Head': 500,
      'Content': 100,
      'Progress': 50,
      'IframeDlgs': 75,
      'Toolbar': 150,
      'ToolbarSubMenu': 95
    },
    'header': {
      'Height': '5.250em'
    },
    'search': {
      'bdrs': '1.125em'
    },
    'subheaderMargin': {
      'Bottom': '.5em'
    }
  };

let globalStyles = {};

if (ENABLE_REBRANDING_PHASE_1) {
  // primary brand colors //
  const lifewayYellow = '#fdb528';
  const lifewayGray = '#414042';
  const lifewayWhite = '#FFF';
  const lifewayOffWhite = '#F5F5F5';
  const lifewayLeadershipBlue = '#2586BF';
  const lifewayYouthTeal = '#24A4AB';
  const lifewayWomenLavender = '#8193C5';

  // supporting colors //
  const pacific = '#136172';
  const indigo = '#005B7E';
  const honeyCreeper = '#476692';
  const lambsEar = '#B1C9BC';
  const dartFrog = '#B1D0D3';
  const lilac = '#D9D5E5';
  const progressBlue = '#0076C0';
  const mediaBadgeIcon = '#D1A815';
  const required = '#a6403e';

  // supporting neutrals //
  const cloud = '#dedad3';
  const dust = '#a5a29c';
  const camel = '#C19066';
  const earth = '#3B4948';

  // MLC specific colors //
  const mlcLightGray = '#F0F0EF';

  globalStyles = {
      ...baseStyles,
      colors: {
        ...baseStyles.colors,
        Primary: honeyCreeper,
        PrimaryHover: honeyCreeper,
        ButtonPrimary: honeyCreeper,
        DarkGray: lifewayGray,
        ButtonTextPrimary: lifewayWhite,
        lifewayWhite: lifewayWhite,
        LifewayYellow: lifewayYellow,
        LifewayOffWhite: lifewayOffWhite,
        Form: mlcLightGray,
        DartFrog: dartFrog,
        Pacific: pacific,
        Indigo: indigo,
        HoneyCreeper: honeyCreeper,
        Progress: progressBlue,
        MediaBadgeIcon: mediaBadgeIcon,
        Required: required,
      }
    };
} else {
    globalStyles = baseStyles;
}

export default globalStyles;
