import { str as stringToObj } from 'dot-object';

export default function HTML5Validator() {
  let form;

  return {
    setForm(el) {
      if (el) form = el;
    },

    validate() {
      let errors = {};

      if (form) {
        [].slice.call(form.elements).forEach(node => {
          node.setCustomValidity('');

          if (!node.checkValidity()) {
            errors = stringToObj(node.name, true, errors);
          }
        });
      }

      return errors;
    }
  };
}

export function truncateString(str, maxLength = 40) {
  return str?.length > maxLength ? str?.slice(0, maxLength) + '...' : str;
}

export function fontResize(str) {
  const length = str?.length;
  if (length >= 40 && length <=60) {
    return '15px'
  } else if (length > 60) {
    return '14px'
  } else {
    return '16px'
  }
};

// simple input masking, replaces '*' in pattern
// text=foo, pattern=*-*-* would produce f-o-o
export function inputMask(text, pattern) {
  if (!text || text.length === 0) {
    return '';
  }

  if (!pattern) {
    return text;
  }

  const formattedObject = pattern.split('').reduce((acc, character) => {
    if (acc.remainingText.length === 0) {
      return acc;
    }

    if (character !== '*') {
      return {
        formattedText: acc.formattedText + character,
        remainingText: acc.remainingText
      };
    }

    return {
      formattedText: acc.formattedText + (acc.remainingText[0] || ''),
      remainingText: acc.remainingText.slice(1)
    };
  }, { formattedText: '', remainingText: text.split('') });

  return formattedObject.formattedText + formattedObject.remainingText.join('');
}


// strips all characters except numbers
export function onlyNumbers(str) {
  return str.replace(/\D/g, '');
}


// strips all special characters
export function noSpecialCharacters(str) {
  return str.replace(/[^a-zA-Z0-9]*/g, '');
}


// formats text as you type; used for redux-form field normalize/format
export function asYouType(value, previousValue, pattern, formatFunc, processFunc) {
  if (!value) return value;

  const lastValue = value.substr(0, value.length - 1);
  const currentChar = value.split('').pop();
  const patternChar = pattern.charAt(lastValue.length);

  const processed = (processFunc) ? processFunc(value) : value;
  const formatted = formatFunc(processed, pattern);
  let rest = '';
  if (previousValue && formatted.length > previousValue.length) {
    for (let i = formatted.length; i < pattern.length; i += 1) {
      if (pattern.charAt(i) === '*') break;
      rest += pattern.charAt(i);
    }
  }
  if (currentChar === patternChar) {
    return lastValue + currentChar;
  }
  if (processFunc(currentChar) === '') {
    return lastValue;
  }
  return formatted + rest;
}


// returns formatted placeholder for form fields
export function getPlaceholder(pattern, formatFunc, patternString = '', paddingChar = '9') {
  const digits = pattern.replace(/[^*]/g, '').length;
  let str = patternString;
  if (patternString === '') {
    for (let i = 0; i < digits; i += 1) str += paddingChar;
  }
  return formatFunc(str, pattern);
}


// as-you-type format for MM/DD/YYYY date
export function asYouTypeDateFormat(options, value) {
  let output = '';
  let month;
  let day;
  let year;
  const val = (undefined === value) ? options : value;
  const minAge = 0;
  const maxAge = 130;
  const currentYear = new Date().getFullYear();
  const maxYear = options.maxYear || currentYear - minAge;
  const minYear = options.minYear || currentYear - maxAge;
  const decade = String(maxYear).substr(0, 3);
  const daysInMonth = (m, y) => new Date(y, m, 0).getDate();
  const segments = val.split('/');

  // there is a month segment
  if (segments[0].length) {
    month = onlyNumbers(segments[0]);
    if (segments.length === 1 && month.length > 2) {
      segments.push(month.substr(2));
    }
    month = month.substr(0, 2);
    month = parseInt(month, 10);
    if ((val.length === 1 && month > 1) ||
      (val.length > 1 && month < 10)) month = `0${month}`;

    if (month > 12) month = '12';
    if (month === '00') month = '0';
    month = onlyNumbers(`${month}`);
    output += month;
  }

  // there is a day segment
  if (segments[1] && segments[1].length) {
    day = onlyNumbers(segments[1]);
    if (segments.length === 2 && day.length > 2) {
      segments.push(day.substr(2));
    }
    const parsed = parseInt(day, 10);
    if (parsed > 3 && parsed < 10) {
      day = `0${parsed}`;
    }
    if (day === '00') day = '0';
    if (day.length > 2) day = day.substr(0, 2);
  }

  // there is a year segment
  if (segments[2] && segments[2].length) {
    year = onlyNumbers(segments[2]);
    year = parseInt(year.substr(0, 4), 10);
    if (year < 10 && year > 2) year = 2;
    if (year < 10 && year < 1) year = 1;
    if (year > 9 && year < 18) year = 18;
    if (year < 100 && year > 20) year = 20;
    if (year < 1000 && year > decade) year = decade;
    if (year > maxYear) year = maxYear;
    if (year > 999 && year < minYear) year = minYear;
  }
  if (day) {
    const yyyy = (year >= minYear) ? year : 2000;
    const maxDate = daysInMonth(month, yyyy);
    if (day > maxDate) day = maxDate;
    output += day;
  }
  if (year) output += year;

  return output;
}

export function entityDecode(entity) {
  const e = document.createElement('div');
  e.innerHTML = entity;
  return e.childNodes[0].nodeValue;
}

export function getQueryParams() {
  if (!location.search) return false;
  return Array.from(new URLSearchParams(location.search))
    .reduce((acc, [name, value]) => Object.assign(acc, { [name]: value }), {});
}

/* General Email Regex (RFC 5322 Official Standard) */
export function isEmail(string) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
}
