import { createLogic } from 'redux-logic';
import { useSelector } from 'react-redux';
import React from 'react';
import moment from 'moment';
import {
  FETCH_ACH_PAYMENT_METHODS_PENDING,
  SUBMIT_ACH_INVOICE_PAYMENT_PENDING,
  ADD_ACH_PAYMENT_METHOD_PENDING,
  DELETE_ACH_PAYMENT_METHOD_CONFIRM,
  DELETE_ACH_PAYMENT_METHOD_PENDING,
  SET_ACH_PAYMENT_DEFAULT_METHOD_PENDING,
} from '../constants';
import {
  fetchAchPaymentMethodsSuccess,
  fetchAchPaymentMethodsError,
  submitAchInvoicePaymentSuccess,
  submitAchInvoicePaymentError,
  getAccountStatementsPending,
  getAccountBalancePending,
  getAccountTransactionsPending,
  addACHPaymentMethodSuccess,
  addACHPaymentMethodError,
  fetchAchPaymentMethodsPending,
  setBannerState,
  deleteAchPaymentMethodPending,
  toggleModal,
  triggerModal,
  deleteAchPaymentMethodSuccess,
  deleteAchPaymentMethodError,
  setACHPaymentDefaultMethodSuccess,
  setACHPaymentDefaultMethodError
} from '../actions/AppActions';
import intl from '../actions/lang/en.json';

export const submitAchInvoicePaymentLogic = createLogic({
  type: SUBMIT_ACH_INVOICE_PAYMENT_PENDING,
  latest: true,
  process({ action, httpClient, getState }, dispatch, done) {
    const { setSubmitting, resetForm, submitData } = action.payload;
    const state = getState();
    const bannerLocation = '';

    const {
      billingAccount: {
        id: billingAccountId, accountNumber, organizationId, organizationName
      },
      useExistingAccount,
      save,
      displayName,
      achPaymentMethodId,
      paymentAmount,
      paymentMethodNonce,
      ...otherFields
    } = submitData;

    const account = {
      accountNumber,
      organizationId,
      organizationName,
      ...otherFields
    };

    const paymentMethod = useExistingAccount
      ? { achPaymentMethodId }
      : {
        paymentMethodNonce,
        save: save,
        displayName: save ? displayName : undefined,
      };

    const body = {
      ...account,
      paymentAmount,
      ...paymentMethod,
    };

    return httpClient(dispatch).post(`/organizations/${organizationId}/ach-payments`, body)
      .then(res => {
        resetForm(true);
        dispatch(submitAchInvoicePaymentSuccess({ ...res.data, saved: save, paymentAmount, id: billingAccountId, isSuccessfulPayment: true }));

        // Refresh account activity data after successful payment //
        dispatch(getAccountStatementsPending({id: billingAccountId, accountNumber, organizationId }));
        dispatch(getAccountTransactionsPending({ id: billingAccountId, accountNumber, fromDate: moment().subtract(1, 'years').startOf('month').toISOString(), toDate: moment().toISOString(), setSubmitting, achForm: true }));

        if (save) {
          dispatch(fetchAchPaymentMethodsPending(accountNumber, organizationId, bannerLocation));
        }
        done();
      })
      .catch(error => {
        const errors = state?.intl?.messages?.Errors?.BillingAccs || {};
        let achError = { type: "", message: "", preventResubmission: false };
        const stringifiedError = JSON.stringify(error?.response?.data);
        const isDuplicateError = stringifiedError?.toLowerCase()?.includes('duplicate payment');

        if (error?.response?.status === 504) {
          achError = {
            type: "warning",
            message: errors.ACH_Payment_Timeout_Error,
            preventResubmission: true,
          };
        } else if (error?.response?.status === 406 && isDuplicateError) {
          achError = {
            type: "warning",
            message: errors.ACH_Payment_Duplicate_Error,
            preventResubmission: true,
          };
        } else {
          achError = {
            type: "error",
            message: errors.ACH_Payment_Error,
          };
        }
        resetForm(true);
        setSubmitting(false);
        dispatch(submitAchInvoicePaymentError(achError));
        done();
      });
  }
});

export const fetchACHPaymentMethodsLogic = createLogic({
  type: FETCH_ACH_PAYMENT_METHODS_PENDING,
  latest: true,
  process({ action, httpClient }, dispatch, done) {
    const { accountNumber, organizationId, bannerLocation } = action.payload;
    return httpClient(dispatch)
      .get(`/organizations/${organizationId}/billing-accounts/${accountNumber}/ach-payment-methods`)
      .then(res => dispatch(fetchAchPaymentMethodsSuccess(res.data)))
      .catch(error => {
        const errorMessage = intl.Errors.PaymentMethods.Add_ACH_Payment_Method_Error;
        dispatch(setBannerState({ data: { type: 'error', message: errorMessage, location: (bannerLocation || '') } }));
        dispatch(fetchAchPaymentMethodsError(error));
      })
      .finally(() => done());
  }
});

export const addACHPaymentMethodLogic = createLogic({
  type: ADD_ACH_PAYMENT_METHOD_PENDING,
  latest: true,
  process({ action, httpClient }, dispatch, done) {
    const { setSubmitting, resetForm, submitData, personProfileId, setBankAccountCreated, bannerLocation, handleCancel } = action.payload;
    const { organizationId } = submitData;
    const config = {
      headers: {
        requestingUserId: personProfileId
      },
    };
    return httpClient(dispatch)
      .post(`/organizations/${organizationId}/ach-payment-methods`, submitData, config)
      .then(res => {
        resetForm(true);
        handleCancel();
        dispatch(addACHPaymentMethodSuccess(res.data));
        setBankAccountCreated(true);
      })
      .catch(error => {
        let errorMessage;
        if (error?.response?.data?.errorCode === 'duplicate.display.name') {
          errorMessage = intl.Errors.PaymentMethods.Add_ACH_Payment_Method_Duplicate;
        } else {
          errorMessage = intl.Errors.PaymentMethods.Add_ACH_Payment_Method_Error;
        }
        dispatch(setBannerState({ data: { type: 'error', message: errorMessage, location: (bannerLocation || '') } }));
        setSubmitting(false);
        setBankAccountCreated(false);
        dispatch(addACHPaymentMethodError(error));
      })
      .finally(() => done());
    }
});

export const setACHPaymentDefaultMethodLogic = createLogic({
  type: SET_ACH_PAYMENT_DEFAULT_METHOD_PENDING,
  latest: true,
  process({ action, httpClient }, dispatch, done) {
    const { organizationId, achPaymentMethodId, accountNumber } = action.payload;
    return httpClient(dispatch)
      .put(`/organizations/${organizationId}/ach-payment-methods/${achPaymentMethodId}/default/account/${accountNumber}`)
      .then(() => {
        dispatch(setACHPaymentDefaultMethodSuccess(achPaymentMethodId));
        dispatch(setBannerState({ data: { type: 'success', message: intl.Success.PaymentMethods.Success_Setting_Default_Bank_Account, location: `setDefaultAchSuccess-${achPaymentMethodId}` } }));
      })
      .catch(error => {
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.PaymentMethods.Error_Setting_Default_Bank_Account, location: `setDefaultAchError-${achPaymentMethodId}`} }));
        dispatch(setACHPaymentDefaultMethodError(error));
      })
      .finally(() => done());
    }
});

export const deleteACHPaymentMethodConfirmationLogic = createLogic({
  type: DELETE_ACH_PAYMENT_METHOD_CONFIRM,
  latest: true,
  warnTimeout: 0,
  process({ action }, dispatch, done) {
    const { organizationId, achPaymentMethodId, displayName, accountNumber } = action.payload;
    const modalProps = {
      show: true,
      message: <>You are about to delete &quot;{displayName}&quot;.<br />Please confirm this action.</>,
      okLabel: 'Yes',
      okDataHook: 'deleteACH.yes',
      cancelLabel: 'No',
      cancelDataHook: 'deleteACH.no',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(deleteAchPaymentMethodPending(organizationId, achPaymentMethodId, accountNumber));
        dispatch(toggleModal());
        done();
      },
      cancelClick: () => {
        dispatch(toggleModal());
        done();
      }
    };
    dispatch(triggerModal(modalProps));
  }
});

export const deleteACHPaymentMethodLogic = createLogic({
  type: DELETE_ACH_PAYMENT_METHOD_PENDING,
  latest: true,
  process({ action, httpClient, getState }, dispatch, done) {
    const state = getState();
    const intl = state.intl.messages;

    const { organizationId, achPaymentMethodId, accountNumber } = action.payload;
    return httpClient(dispatch)
      .delete(`/organizations/${organizationId}/ach-payment-methods/${achPaymentMethodId}/account/${accountNumber}`)
      .then(() => {
        dispatch(deleteAchPaymentMethodSuccess(achPaymentMethodId));
        dispatch(setBannerState({ data: { type: 'success', message: intl.Success.PaymentMethods.Success_Deleting_Bank_Account, location: `deleteAchSuccess-${achPaymentMethodId}` } }));
      })
      .catch(error => {
        dispatch(deleteAchPaymentMethodError(error));
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.PaymentMethods.Error_Deleting_Bank_Account, location: `deleteAchError-${achPaymentMethodId}`} }));
      })
      .finally(() => done());
  }
});
export default [
  fetchACHPaymentMethodsLogic,
  submitAchInvoicePaymentLogic,
  addACHPaymentMethodLogic,
  deleteACHPaymentMethodConfirmationLogic,
  deleteACHPaymentMethodLogic,
  setACHPaymentDefaultMethodLogic
];
