import { createLogic } from 'redux-logic';
import { GET_PRODUCTS_PENDING, FETCH_PRODUCT_IMAGE_URLS, GET_PRODUCT_AVAILABILITY_PENDING, GET_PRODUCT_ALL_DETAILS_PENDING, FIND_PRODUCTS_REPLACEMENTS_PENDING, GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_PENDING } from '../constants';
import {
  getProductsSuccess,
  getProductsError,
  fetchProductImageURLsSuccess,
  fetchProductImageURLsError,
  getProductAvailabilitySuccess,
  getProductAvailabilityError,
  getProductAllDetailsSuccess,
  getProductAllDetailsError,
  findProductsReplacementsSuccess,
  findProductsReplacementsError,
  findProductsReplacementsPending,
  setProductImagesLoading
} from '../actions/AppActions';

export const fetchProductImageUrlsLogic = createLogic({
  type: FETCH_PRODUCT_IMAGE_URLS,
  validate({ getState, action }, allow, reject) {
    const existingItems = getState().app.get('products');

    const itemNumbers = action?.payload?.itemNumbers
      .filter(itemNumber => !existingItems[itemNumber]?.imageUrl);

    if (Array.isArray(itemNumbers) && itemNumbers.length > 0) {
      allow({
        ...action,
        payload: {
          ...action.payload,
          itemNumbers
        }
      });
    } else {
      reject(action);
    }
  },
  process({ httpClient, action }, dispatch, done) {
    const itemNumbers = action?.payload?.itemNumbers;
    httpClient(dispatch).post('/product-images', itemNumbers)
      .then((res) => {
        dispatch(fetchProductImageURLsSuccess(res?.data));
      })
      .catch(err => {
        dispatch(fetchProductImageURLsError(err));
      })
      .finally(() => done());
  }
});

export const fetchProductsByItemNumbersLogic = createLogic({
  type: GET_PRODUCTS_PENDING,
  validate({ action }, allow, reject) {
    const itemNumbers = action?.payload?.itemNumbers;
    if (Array.isArray(itemNumbers) && itemNumbers.length > 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ httpClient, action }, dispatch, done) {
    const itemNumbers = action?.payload?.itemNumbers;
    httpClient(dispatch).post('/product-images', itemNumbers)
      .then(res => {
        dispatch(getProductsSuccess({ type: action?.payload?.type, data: res?.data }));
        dispatch(fetchProductImageURLsSuccess(res?.data));
        dispatch(setProductImagesLoading({ type: action?.payload?.type }));
      })
      .catch(err => {
        dispatch(setProductImagesLoading({ type: action?.payload?.type }));
        dispatch(getProductsError(err));
      })
      .finally(() => done());
  }
});

export const getProductAvailabilityLogic = createLogic({
  type: GET_PRODUCT_AVAILABILITY_PENDING,
  validate({ action }, allow, reject) {
    const { itemNumbers } = action.payload;
    if (Array.isArray(itemNumbers) && itemNumbers.length > 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ httpClient, getState, action }, dispatch, done) {
    const existingProducts = getState().app.get('products').toJS();

    const itemNumbers = action?.payload?.itemNumbers
      .filter(itemNumber => !existingProducts[itemNumber]?.availability);

    if (itemNumbers.length === 0) {
      done();
      return;
    }

    httpClient(dispatch).get('/products/availability', { params: { itemNumber: itemNumbers.join(',') } })
      .then(({ data }) => {
        dispatch(getProductAvailabilitySuccess(data));
      })
      .catch(error => {
        dispatch(getProductAvailabilityError(itemNumbers, error));
      })
      .finally(() => done());
  }
});

export const getAllProductDetailsLogic = createLogic({
  type: GET_PRODUCT_ALL_DETAILS_PENDING,
  validate({ action }, allow, reject) {
    const { itemNumbers } = action.payload;
    if (Array.isArray(itemNumbers) && itemNumbers.length > 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ httpClient, getState, action }, dispatch, done) {
    const existingProducts = getState().app.get('products').toJS();

    const itemNumbers = action?.payload?.itemNumbers
      .filter(itemNumber => (
        !existingProducts[itemNumber]?.imageUrl ||
        !existingProducts[itemNumber]?.pricingTiers ||
        !existingProducts[itemNumber]?.availability ||
        !existingProducts[itemNumber]?.details
      ));

    if (itemNumbers.length === 0) {
      done();
      return;
    }

    httpClient(dispatch).get('/products/all-details',
      { params: { itemNumbers: itemNumbers.join(','), channel: 'lifeway' } }
    )
    .then(({ data }) => {
      dispatch(getProductAllDetailsSuccess(data));
    })
    .catch(err => {
      dispatch(getProductAllDetailsError(err));
    })
    .finally(() => done());
  }
});

export const findProductsReplacementsLogic = createLogic({
  type: FIND_PRODUCTS_REPLACEMENTS_PENDING,
  validate({ action }, allow, reject ) {
    const { items } = action.payload;
    if (Array.isArray(items) && items.length > 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ httpClient, getState, action }, dispatch, done) {
    const { items } = action.payload;

    httpClient(dispatch).post('/products/find-replacements', items.map(i => i.itemId))
    .then(({ data }) => {
      const existingProducts = getState ? getState().app.get('products').toJS() : {};
      const products = data
        .map(product => ({
          ...product,
          productName: items.find(i => i.itemId === product.itemNumber).productTitle,
          replacements: product.replacements.reduce((acc, curr) => {
            const existingReplacement = acc.find(p => p.itemNumber === curr.itemNumber);
            const otherReplacements = acc.filter(p => p.itemNumber !== curr.itemNumber);

            const replacement = {
              ...existingReplacement,
              itemNumber: curr.itemNumber,
              productName: curr.productName,
              choQuarter: existingProducts[product.itemNumber]?.details.autoshipType === 'Fixed Frequency' && curr.choQuarter ? curr.choQuarter : [],
              imageId: curr.imageId?.split(',')[0],
              pricingTiers: [
                ...(existingReplacement?.pricingTiers || []),
                {
                  minimumQuantity: curr.minimumQuantity,
                  listPrice: curr.listPrices[0],
                  purchasePrice: curr.purchasePrices[0]
                }
              ]
            };

            return [
              ...otherReplacements,
              replacement
            ];
          }, [])
        }));

      dispatch(findProductsReplacementsSuccess(products));
    })
    .catch(err => {
      dispatch(findProductsReplacementsError(err));
    })
    .finally(() => done());
  }
});

export const getProductAllDetailsAndFindProductsReplacementsLogic = createLogic({
  type: GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_PENDING,
  validate({ action }, allow, reject) {
    const { items } = action.payload;
    const itemNumbers = items?.map(item => item.itemId);
    if (Array.isArray(itemNumbers) && itemNumbers.length > 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ httpClient, getState, action }, dispatch, done) {
    const existingProducts = getState().app.get('products').toJS();

    const items = action?.payload?.items;
    const itemNumbers = items?.map(item => item.itemId)
      .filter(itemNumber => (
        !existingProducts[itemNumber]?.imageUrl ||
        !existingProducts[itemNumber]?.pricingTiers ||
        !existingProducts[itemNumber]?.availability ||
        !existingProducts[itemNumber]?.details
      ));

    if (itemNumbers.length === 0) {
      dispatch(findProductsReplacementsPending(items));
      done();
      return;
    }

    httpClient(dispatch).get('/products/all-details',
      { params: { itemNumbers: itemNumbers.join(','), channel: 'lifeway' } }
    )
    .then(({ data }) => {
      dispatch(getProductAllDetailsSuccess(data));
      dispatch(findProductsReplacementsPending(items));
    })
    .catch(err => {
      dispatch(getProductAllDetailsError(err));
    })
    .finally(() => done());
  }
});

export default [
  fetchProductImageUrlsLogic,
  fetchProductsByItemNumbersLogic,
  getProductAvailabilityLogic,
  getAllProductDetailsLogic,
  findProductsReplacementsLogic,
  getProductAllDetailsAndFindProductsReplacementsLogic
];
