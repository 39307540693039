import React from 'react';
import { menuLinks } from './header/account-menu/menuLinks';
import styled from 'styled-components';
import { PATH_DASHBOARD } from "../constants";
import { RedeemButton } from './RedeemButton';
import { Link } from 'react-router-dom';
import DownChevron from '../../../img/sprite/left-chevron-blue.svg';
import { ButtonContainer, SignOutText } from './SideNavSelect';
import { GroupTitle, StyledChevron } from './SideNavOrg';
import css from '../../../css/css-variables';
import UserIcon from '../../../img/sprite/indv-user-icon.svg';
import SignOutIcon from '../../../img/sprite/sign-out-icon.svg';

const StyledLink = styled(Link)`
  border: none;
  font-size: 16px;
  color: ${css.colors.Progress};
  text-decoration: none;
  ${({activeLink}) => activeLink && 'pointer-events: none'};
  font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
  padding: 8px 0;

  &:hover {
    text-decoration: ${({activeLink}) => activeLink ? 'none' : 'underline'};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0 10px;
`;

export const StyledSvg = styled.svg`
  height: ${({height}) => height ? height : '48px'};
  width: ${({width}) => width ? width : '48px'};
  ${({ flexShrink }) => flexShrink && `flex-shrink: ${flexShrink};`}
  margin-right: ${({marginRight}) => marginRight ? marginRight : '12px'};
  ${({ display }) => display && `display: ${display}`};
`;

export const SectionHeader = styled.h1`
  font-size: ${({ fontSize}) => fontSize ? fontSize : '16px' };
  color: ${css.colors.DarkGray};
  text-decoration: none;
  font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
  width: fit-content;
`;


function SideNavIndividual({
  pathname,
  showBackBtn,
  view,
  setView,
  onLogOut,
  currentUser
}) {
  const defaultPath = PATH_DASHBOARD;
  const userName = currentUser?.fullName;
  
  return (
    <>
      {showBackBtn && view !== 'SELECT' && (
        <div style={{ display: 'flex', width: '100%', borderBottom: 'solid 1px #e0e0e0', padding: '16px 0', alignItems: 'center', cursor: 'pointer' }} onClick={() => setView('SELECT')}>
          <StyledChevron><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></StyledChevron>
          <button type="button" style={{ fontWeight: '800', border: 'none', fontSize: '14px', color: css.colors.Progress, textDecoration: 'none' }}>
            Switch Account
          </button>
        </div>
      )}
      <div>
        <HeaderWrapper>
          <StyledSvg flexShrink="0">
            <use href={`#${UserIcon.id}`} />
          </StyledSvg>
          <SectionHeader bold={true}>
            {userName}
          </SectionHeader>
        </HeaderWrapper>
        <div style={{padding: '0 0 7px 0'}}>
          <StyledLink
            to={PATH_DASHBOARD}
            style={{
              fontSize: '14px',
              color: pathname === PATH_DASHBOARD ? '#414042' : css.colors.Progress,
              fontWeight: pathname === PATH_DASHBOARD ? 'bold' : 'normal'
            }}
            activeLink={pathname === PATH_DASHBOARD}
          >
            Home
          </StyledLink>
        </div>
        {menuLinks.linkGroup.map((group, index) => {
        const isLastChild = menuLinks.linkGroup.length -1 === index;
        return (
          <div key={index}>
            <GroupTitle isLastChild={isLastChild} title={group.title}>{group.title}</GroupTitle>
            {group.links.map((link, index) => {
              const isLastChild = group.links.length -1 === index;
              return (<div style={isLastChild ? { padding : '0 0 14px 0'} : { padding: '0 0 8px 0'}}>
                <StyledLink
                  bold={false}
                  to={link.url}
                  style={{
                    marginTop: '0',
                    fontSize: '14px',
                    color: pathname === link.url ? '#414042' : pathname.includes('manage-access') && link.url.includes('manage-access') ? '#414042' : css.colors.Progress,
                    fontWeight: pathname === link.url ? 'bold' : pathname.includes('manage-access') && link.url.includes('manage-access') ? 'bold' : 'normal',
                  }}
                  activeLink={pathname === link.url}
                >
                  {link.name}
                </StyledLink>
              </div>
            )})}
          </div>
        )})}
        <ButtonContainer>
          <RedeemButton />
          <button data-tracking-component="button" data-tracking-id="nav-sign-out-btn" data-qa-hook="nav-sign-out-btn" type="button" onClick={onLogOut} style={{ fontSize: '14px', color: '#476692', background: 'transparent', display: 'flex', gap: '4px' }}>
            <StyledSvg marginRight="0" width="18px" height="18px"> 
              <use href={`#${SignOutIcon.id}`} />
            </StyledSvg>
            <SignOutText>Sign Out</SignOutText>
          </button>
        </ButtonContainer>
      </div>
    </>
  );
}

export default SideNavIndividual;