import { createLogic } from 'redux-logic';
import { stopSubmit, reset, setSubmitSucceeded, setSubmitFailed } from 'redux-form';
import { GET_GIFT_CARD_BALANCE_PENDING, APP_TOGGLE_MODAL, APP_TRIGGER_MODAL, EMAIL_GIFT_CARD_PENDING } from '../constants';
import {
  getGiftCardBalanceSuccess,
  getGiftCardBalanceError,
  setCurrentGiftCard,
  setBannerState,
  getGiftCardPurchasesPending,
  getGiftCardPurchasesSuccess,
  getGiftCardPurchasesError,
  tableUpdateSettings,
  toggleProcessedItem,
  updateGiftCardBalance
} from '../actions/AppActions';
import { guidToDisplayID, formatOrderDate } from '../utils/orderHelpers';
import { isEmail } from '../utils/formUtils';

export const getGiftCardModalLogic = createLogic({
  type: [APP_TOGGLE_MODAL, APP_TRIGGER_MODAL],
  latest: true,
  process({ action }, dispatch, done) {
    if (action.payload && action.payload.show !== 'emailGiftCard') {
      dispatch(setCurrentGiftCard());
    }
    done();
  }
});

export const getGiftCardBalanceLogic = createLogic({
  type: GET_GIFT_CARD_BALANCE_PENDING,
  latest: true,
  validate({ action }, allow, reject) {
    if (action?.payload?.cardNumber && action?.payload?.pin) {
      allow(action);
    } else {
      reject(stopSubmit('giftCardBalance', { pin: !action.payload.pin, cardNumber: !action.payload.cardNumber }));
    }
  },
  process({ httpClient, action, getState }, dispatch, done) {
    const state = getState();
    const intl = state.intl.messages;
    const form = 'giftCardBalance';
    let rows = [];
    if (action.payload.inline) {
      const item = action.payload.inline === 'print' ? 'fetching...' : action.payload.cardNumber;
      dispatch(toggleProcessedItem(item));
      rows = (state.app.getIn(['giftCardPurchases', 'rows']) || { toJS: () => ({}) }).toJS();
    }
    httpClient(dispatch).get(`/gift-cards/${action.payload.cardNumber}/balance`, { params: { pin: action.payload.pin } })
      .then(res => {
        const balance = `$${res.data.message}`;
        dispatch(getGiftCardBalanceSuccess(balance));
        if (action.payload.inline) {
          const item = action.payload.inline === 'print' ? balance : null;
          dispatch(toggleProcessedItem(item));
          if (rows.findIndex) {
            const index = rows.findIndex(row => row.cardNumber === action.payload.cardNumber);
            dispatch(updateGiftCardBalance({ index, data: { balance } }));
          }
        } else {
          dispatch(stopSubmit(form));
          dispatch(setCurrentGiftCard({ cardNumber: action.payload.cardNumber, pin: action.payload.pin, balance }));
          dispatch(setSubmitSucceeded(form));
          dispatch(reset(form));
        }
        done();
      })
      .catch(error => {
        const getMessage = () => {
          switch(error?.response?.data?.message) {
            case 'Invalid PIN Number ':
              return intl.Errors.PaymentMethods.Gift_Card_Pin_Field_Error;
            case 'No Card Found':
              return intl.Errors.PaymentMethods.Gift_Card_Number_Field_Error;
            default:
              return intl.Errors.PaymentMethods.Gift_Card_Balance_Error;
          }
        };

        const bannerState = {
          data: {
            type: 'error',
            message: action.payload.inline ? intl.Errors.PaymentMethods.Gift_Card_Check_Balance_Error : getMessage()
          }
        };
        dispatch(setBannerState(bannerState));
        dispatch(getGiftCardBalanceError(error));
        if (action.payload.inline) {
          dispatch(toggleProcessedItem());
        } else {
          dispatch(setCurrentGiftCard());
          dispatch(setSubmitFailed(form));
        }
        done();
      });
  }
});

export const getGiftCardsLogic = createLogic({
  type: getGiftCardPurchasesPending().type,
  latest: true,
  process({ httpClient, getState }, dispatch, done) {
    const state = getState();
    const intl = state.intl.messages;
    const tableName = 'giftCardPurchases';
    dispatch(tableUpdateSettings({ tableName, settings: { isLoading: true } }));
    httpClient(dispatch).get('/gift-cards')
      .then(res => {
        const cards = res?.data?.length && res.data.map(card => ({
          amountPurchased: `$${card.amount}`,
          cardNumber: card.number,
          datePurchased: formatOrderDate(card.issueDate),
          orderNumber: guidToDisplayID(card.orderId),
          pin: card.pin,
        }));
        const tableData = {
          data: {
            rows: cards,
            totalRows: cards.length,
          }
        };
        dispatch(getGiftCardPurchasesSuccess(tableData));
        dispatch(tableUpdateSettings({ tableName, settings: { isLoading: false } }));
        done();
      })
      .catch(err => {
        const bannerState = {
          data: { type: 'error', message: intl.Errors.GiftCards.Get_Purchases_Error }
        };
        if (err.status !== 404) {
          dispatch(setBannerState(bannerState));
        } else {
          dispatch(getGiftCardPurchasesSuccess({ data: { rows: [] } }));
          dispatch(tableUpdateSettings({ tableName, settings: { isLoading: false } }));
        }
        dispatch(getGiftCardPurchasesError(err));
        done();
      });
  }
});

export const emailGiftCardLogic = createLogic({
  type: EMAIL_GIFT_CARD_PENDING,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const emailAddresses = action?.payload?.toAddress?.split(',')?.filter(address => isEmail(address?.trim()));
    const currentUserEmail = getState().app.getIn(['currentUser', 'emailAddress']);
    const recipients = action?.payload?.copyMe && isEmail(currentUserEmail)
      ? [...emailAddresses, currentUserEmail]
      : emailAddresses;
    if (recipients.length) {
      allow({ ...action, payload: { ...action?.payload, recipients } });
    } else {
      reject(stopSubmit('emailGiftCard', { toAddress: true }));
    }
  },
  process({ httpClient, action, getState }, dispatch, done) {
    const intl = getState().intl.messages;
    const form = 'emailGiftCard';
    return (
      httpClient(dispatch).post(`/gift-cards/${action?.payload?.cardNumber}/email`, { recipients: action?.payload?.recipients })
        .then(() => {
          const bannerState = {
            data: { type: 'success', message: intl.Errors.PaymentMethods.Gift_Card_Email_Success_Msg }
          };
          dispatch(setBannerState(bannerState));
          dispatch(stopSubmit(form));
          dispatch(setSubmitSucceeded(form));
          dispatch(reset(form));
          done();
        })
        .catch(() => {
          const bannerState = {
            data: { type: 'error', message: intl.Errors.PaymentMethods.Gift_Card_Email_Error }
          };
          dispatch(setBannerState(bannerState));
          dispatch(stopSubmit(form));
          dispatch(setSubmitFailed(form));
          done();
        })
    );
  }
});

export default [
  getGiftCardModalLogic,
  getGiftCardBalanceLogic,
  getGiftCardsLogic,
  emailGiftCardLogic
];
