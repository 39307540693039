import { createLogic } from 'redux-logic';
import { reset, setSubmitSucceeded } from 'redux-form';
import {
  GET_LICENSES_PENDING,
  GET_LICENSE_PENDING,
  GRANT_ACCESS_ACCOUNT_CHECK,
  GRANT_SEAT_PENDING,
  REVOKE_SEAT_PENDING,
  ADD_MANAGER_PENDING,
  REMOVE_MANAGER_PENDING,
  CONFIRM_REVOKE_SEAT,
  ENABLE_MANAGE_ACCESS_INVITE,
  SHARE_ACCESS_BY_EMAIL_FORM,
} from '../constants';
import {
  getLicensesSuccess,
  getLicensesError,
  getLicenseSuccess,
  getLicenseError,
  revokeSeatSuccess,
  revokeSeatError,
  clearRevokeError,
  revokeSeatPending,
  getLicensePending,
  addManagerPending,
  grantSeatPending,
  grantSeatError,
  grantSeatSuccess,
  toggleModal,
  triggerModal,
  removeManagerPending,
  getProductsPending,
  removeManagerError,
  removeManagerSuccess,
  setBannerState,
  sendLicenseInvitePending,
  getMediaContentChildrenPending
} from '../actions/AppActions';
import intl from '../actions/lang/en.json';

export const getLicensesLogic = createLogic({
  type: GET_LICENSES_PENDING,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient(dispatch).get('/licenses')
      .then(res => {
        const licenses = res?.data?.licenses;
        const simulcasts = licenses
          ?.filter(license => license.item.distributionChannelCodes ? (license.item.distributionChannelCodes.some(code => code === 'LLC' || code === 'DGP') && license.seats?.count?.total > 1) : false)
          ?.map(simulcast => ({ ...simulcast, item: { ...simulcast.item, itemNumber: simulcast.item.number } }));
        const ebooks = licenses
          ?.filter(license => license.item.distributionChannelCodes ? (license.item.distributionChannelCodes.some(code => code === 'WSA' || code === 'LRA') && license.seats?.count?.total > 1) : false)
          ?.map(ebook => ({ ...ebook, item: { ...ebook.item, itemNumber: ebook.item.number } }));
        const videos = licenses
          ?.filter(license => {
            if (license.item.distributionChannelCodes) {
              const isMultiSeatVideoLicense = license.item.distributionChannelCodes.some(code => code === 'VLC' || code === 'OBS') && license.seats?.count?.total > 1;
              const isTransferrableOBSLicense = license.item.distributionChannelCodes.some(code => code === 'OBS') && !license.seats.isPermanent;
              return isMultiSeatVideoLicense || isTransferrableOBSLicense;
            } else {
              return false;
            }
          })
          ?.map(video => ({ ...video, item: { ...video.item, itemNumber: video.item.number } }));
        dispatch(getLicensesSuccess({ simulcasts, ebooks, videos, data: licenses }));
        if (licenses?.length) {
          const itemNumbers = licenses.map(lic => lic?.item?.number);
          dispatch(getProductsPending({ type: 'licenses', itemNumbers }));
        }
        if (simulcasts?.length) {
          const itemNumbers = simulcasts.map(lic => lic?.item?.number);
          dispatch(getProductsPending({ type: 'licensesSimulcasts', itemNumbers }));
        }
        if (ebooks?.length) {
          const itemNumbers = ebooks.map(lic => lic?.item?.number);
          dispatch(getProductsPending({ type: 'licensesEbooks', itemNumbers }));
        }
        if (videos?.length) {
          const itemNumbers = videos.map(lic => lic?.item?.number);
          dispatch(getProductsPending({ type: 'licensesVideos', itemNumbers }));
        }
        done();
      })
      .catch(err => {
        dispatch(getLicensesError(err));
        done();
      });
  }
});

export const getLicenseLogic = createLogic({
  type: GET_LICENSE_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { licenseId } = action.payload;
    httpClient(dispatch).get(`/licenses/${licenseId}`)
      .then(res => {
        const license = res?.data?.license;
        const managersAndOccupantAccess = [
          ...(license?.managers ? license.managers.map(manager => ({ ...manager, label: 'Organizer' })) : []),
          ...(license?.seats?.occupants ? license.seats.occupants.map(occupant => ({ ...occupant, label: 'User' })) : []),
        ];
        const access = managersAndOccupantAccess.reduce((acc, curr) =>
          acc.map(access => access?.id).includes(curr?.id)
            ? [...acc.filter(access => access.id !== curr?.id), { ...curr, label: 'Organizer & User' }]
            : [...acc, curr]
          , []);
        dispatch(getLicenseSuccess({ ...license, access }));
        dispatch(getMediaContentChildrenPending(license?.item?.number));
        if (license?.item) {
          dispatch(getProductsPending({ type: 'license', itemNumbers: [license.item.number] }));
        }
        done();
      })
      .catch(error => {
        dispatch(getLicenseError(error));
        done();
      });
  }
});

export const grantAccessAccountCheckLogic = createLogic({
  type: GRANT_ACCESS_ACCOUNT_CHECK,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const state = getState();
    const license = state.app.getIn(['licenses', 'singleLicense']).toJS();
    const { inviteeEmail, bannerLocation, isManager, isSeat } = action.payload;
    const client = httpClient(dispatch);
    client.get('/person-email-addresses', {
      params: {
        emailAddress: inviteeEmail
      }
    })
      .then(res => {
        const user = res?.data?.find(personEmailAddresses => personEmailAddresses.isUserName);

        if (user) {
          isSeat && dispatch(grantSeatPending({ licenseId: license.id, personId: user.personId }));
          isManager && dispatch(addManagerPending({ licenseId: license.id, personId: user.personId }));
          dispatch(reset(SHARE_ACCESS_BY_EMAIL_FORM));
        } else {
          if (ENABLE_MANAGE_ACCESS_INVITE) {
            dispatch(sendLicenseInvitePending(action.payload));
          } else {
            dispatch(
              setBannerState({ data: { type: 'error', message: intl.Manage_Acces.Grant_Error, location: (bannerLocation || '') } })
            );
          }
        }
      })
      .catch(() => {
        dispatch(
          setBannerState({ data: { type: 'error', message: intl.Errors.Generic, location: (bannerLocation || '') } })
        );
        dispatch(reset(SHARE_ACCESS_BY_EMAIL_FORM));
      })
      .finally(() => done());
  }
});

export const grantSeatLogic = createLogic({
  type: GRANT_SEAT_PENDING,
  latest: true,
  process({ httpClient, action, delay = 1000 }, dispatch, done) {
    const { licenseId, personId } = action.payload;
    const client = httpClient(dispatch);
    client.post(`/licenses/${licenseId}/occupants`, { occupantId: personId })
      .then(() => {
        //dispatch to get single license on timeOut to give event time to process
        setTimeout(() => {
          dispatch(grantSeatSuccess());
          dispatch(getLicensePending({ licenseId, reloadSimulcast: false }));
          dispatch(reset(SHARE_ACCESS_BY_EMAIL_FORM));
          dispatch(setSubmitSucceeded(SHARE_ACCESS_BY_EMAIL_FORM));
          done();
        }, delay);
      })
      .catch(() => {
        dispatch(grantSeatError());
        dispatch(reset(SHARE_ACCESS_BY_EMAIL_FORM));
        done();
      });
  }
});

export const addManagerLogic = createLogic({
  type: ADD_MANAGER_PENDING,
  latest: true,
  process({ httpClient, action, delay = 1000 }, dispatch, done) {
    const { licenseId, personId } = action.payload;
    const client = httpClient(dispatch);
    client.post(`/licenses/${licenseId}/managers`, { managerId: personId })
      .then(() => {
        //dispatch to get single license on timeOut to give event time to process
        setTimeout(() => {
          dispatch(getLicensePending({ licenseId }));
          dispatch(reset(SHARE_ACCESS_BY_EMAIL_FORM));
          dispatch(setSubmitSucceeded(SHARE_ACCESS_BY_EMAIL_FORM));
          done();
        }, delay);
      })
      .catch(() => {
        dispatch(grantSeatError());
        dispatch(reset(SHARE_ACCESS_BY_EMAIL_FORM));
        done();
      });
  }
});

export const removeManagerLogic = createLogic({
  type: REMOVE_MANAGER_PENDING,
  latest: true,
  process({ httpClient, action, delay = 1000 }, dispatch, done) {
    const { licenseId, personId } = action.payload;
    const client = httpClient(dispatch);
    client.delete(`/licenses/${licenseId}/managers/${personId}`)
      .then(() => {
        setTimeout(() => {
          dispatch(removeManagerSuccess());
          dispatch(getLicensePending({ licenseId }));
          done();
        }, delay);
      })
      .catch(() => {
        dispatch(removeManagerError());
        done();
      });
  }
});

export const revokeSeatLogic = createLogic({
  type: REVOKE_SEAT_PENDING,
  latest: true,
  process({ httpClient, action, delay = 1000, longDelay = 3000 }, dispatch, done) {
    const { licenseId, personId } = action.payload;
    const client = httpClient(dispatch);
    client.delete(`/licenses/${licenseId}/occupants/${personId}`)
      .then(() => {
        //dispatch to get single license on timeOut to give event time to process //
        dispatch(revokeSeatSuccess());
        setTimeout(() => {
          dispatch(getLicensePending({ licenseId, reloadSimulcast: false }));
          done();
        }, delay);
      })
      .catch((e) => {
        dispatch(revokeSeatError());
        setTimeout(() => {
          dispatch(clearRevokeError());
          done();
        }, longDelay);
      });
  }
});

export const revokeSeatConfirmLogic = createLogic({
  type: CONFIRM_REVOKE_SEAT,
  latest: true,
  warnTimeout: 0,
  process({ action: { payload } }, dispatch, done, testSuite) {
    const { name, personId, licenseId, isManager } = payload;

    const modalProps = {
      show: true,
      message: `Are you sure you want to remove all access from ${name}?`,
      okLabel: 'Yes',
      okDataHook: 'removeAllYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'removeAllNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        //dispatch REVOKE_SEAT_PENDING action AND/OR REMOVE_MANAGER action
        dispatch(revokeSeatPending({ licenseId, personId }));
        if (isManager) {
          dispatch(removeManagerPending({ licenseId, personId }));
        }
        dispatch(toggleModal());
        done();
      },
      cancelClick: () => {
        dispatch(toggleModal());
        done();
      }
    };
    dispatch(triggerModal(modalProps));
    //fire done if from test //
    if (testSuite) {
      done();
    }
  }
});

export default [
  getLicensesLogic,
  getLicenseLogic,
  addManagerLogic,
  removeManagerLogic,
  revokeSeatLogic,
  grantSeatLogic,
  revokeSeatConfirmLogic,
  grantAccessAccountCheckLogic
];
