import React from 'react';
import { createLogic } from 'redux-logic';
import { reset } from 'redux-form';
import {
  FETCH_INVITATIONS,
  FETCH_INVITATION,
  FETCH_ORG_INVITATIONS,
  FETCH_LICENSE_INVITATIONS,
  SEND_GROUP_INVITE,
  RESEND_INVITE,
  SEND_LICENSE_INVITE_PENDING,
  RESPOND_TO_INVITE,
  INVITE_CANCELED_CALLBACK_URL,
  INVITE_ORG_CALLBACK_URL,
  INVITE_SEAT_CALLBACK_URL,
  SHARE_ACCESS_BY_EMAIL_FORM,
  ROLES
} from '../constants';
import {
  fetchInvitesSuccess,
  fetchInvitesError,
  respondToInviteSuccess,
  respondToInviteError,
  fetchInviteSuccess,
  fetchInviteError,
  sendGroupInviteSuccess,
  sendGroupInviteError,
  resendInvite,
  resendInviteSuccess,
  resendInviteError,
  fetchPendingInvitesByOrganizationSuccess,
  fetchPendingInvitesByOrganizationError,
  fetchPendingInvitesByLicenseSuccess,
  fetchPendingInvitesByLicenseError,
  setBannerState,
  cancelInviteConfirmation,
  cancelInvitePending,
  cancelInviteSuccess,
  cancelInviteError,
  toggleModal,
  triggerModal,
  sendLicenseInviteError,
  sendLicenseInviteSuccess,
  fetchPendingInvitesByLicense
} from '../actions/AppActions';
import intl from '../actions/lang/en.json';

export const fetchInvitesLogic = createLogic({
  type: FETCH_INVITATIONS,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { inviteeId } = action.payload;
    httpClient(dispatch)
      .get('/invitations', {
        params: {
          inviteeId,
          channel: 'MLC',
          status: 'PENDING'
        }
      })
      .then(resp => resp.data)
      .then(invitations => dispatch(fetchInvitesSuccess(invitations)))
      .catch(error => dispatch(fetchInvitesError(error)))
      .finally(() => done());
  }
});

export const fetchInviteLogic = createLogic({
  type: FETCH_INVITATION,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { inviteId } = action.payload;
    httpClient(dispatch)
      .get(`/invitations/${inviteId}`)
      .then(resp => resp.data)
      .then(invitation => dispatch(fetchInviteSuccess(invitation)))
      .catch(error => dispatch(fetchInviteError(error)))
      .finally(() => done());
  }
});

export const fetchPendingInvitesByOrganization = createLogic({
  type: FETCH_ORG_INVITATIONS,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { organizationId } = action.payload;
    httpClient(dispatch)
      .get('/invitations', {
        params: {
          lookupKey: organizationId,
          channel: 'MLC',
          status: 'PENDING',
        }
      })
      .then(res => dispatch(fetchPendingInvitesByOrganizationSuccess(res.data)))
      .catch(error => dispatch(fetchPendingInvitesByOrganizationError(error)))
      .finally(() => done());
  },
});

export const fetchPendingInvitationsByLicense = createLogic({
  type: FETCH_LICENSE_INVITATIONS,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { licenseId } = action.payload;
    httpClient(dispatch)
      .get('/invitations', {
        params: {
          lookupKey: licenseId,
          channel: 'MLC',
          status: 'PENDING',
        }
      })
      .then(res => dispatch(fetchPendingInvitesByLicenseSuccess(res.data)))
      .catch(error => dispatch(fetchPendingInvitesByLicenseError(error)))
      .finally(() => done());
  }
});

const sendInvite = async (client, { invitee, lookupKey, meta, callbackUrl }) =>
  client.post('/invitations', {
    channel: 'MLC',
    canceledCallbackUrl: INVITE_CANCELED_CALLBACK_URL,
    callbackUrl,
    invitationMessage: 'Welcome!',
    invitee,
    lookupKey,
    meta,
  });

const cancelInvite = (client, inviteId) => client.put(`/invitations/${inviteId}/cancel`);

export const sendLicenseInviteLogic = createLogic({
  type: SEND_LICENSE_INVITE_PENDING,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { inviteeEmail, bannerLocation, errorCallback, successCallback, isManager, isSeat } = action.payload;
    const state = getState();
    const license = state.app.getIn(['licenses','singleLicense']).toJS();

    const manager = state.app.get('currentUser').toJS();
    const userHasExistingSeatRequestedAccess = isSeat && license?.seats?.occupants?.find(o => o.email === inviteeEmail);
    const userHasExistingManagerRequestedAccess = isManager && license?.managers.find(m => m.email === inviteeEmail);
    const pendingSeatInvitations = state.invitations.get('pendingLicenseSeatInvites')?.toJS().data;
    const existingInvitations = pendingSeatInvitations.filter(invite => invite.invitee === inviteeEmail);

    const invitePayload = {
      invitee: inviteeEmail,
      callbackUrl: INVITE_SEAT_CALLBACK_URL,
      lookupKey: license?.id,
      meta: {
        managerName: manager?.fullName,
        managerId: manager?.id,
        licenseId: license?.id,
        productNumber: license?.item?.number,
        distributionChannelCode: license?.item?.distributionChannelCodes?.[0],
        productTitle: license?.item?.title,
        imageUrl: license?.imageUrl,
        seat: isSeat,
        manager: isManager
      }
    };

    if (userHasExistingSeatRequestedAccess || userHasExistingManagerRequestedAccess) {
      const errorMessage = intl.Manage_Access.User_Has_Existing_Requested_Access;
      reject(
        setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', inviteeEmail), location: (bannerLocation || '') } })
      );
      if (errorCallback) errorCallback();
    } else if (existingInvitations?.length > 0) {
      reject(
        resendInvite({
          existingInvitations,
          ...invitePayload,
          bannerLocation,
          successCallback,
          errorCallback,
          formToReset: SHARE_ACCESS_BY_EMAIL_FORM,
        })
      );
    } else {
      allow({ ...action, payload: { ...action.payload, invitePayload, licenseId: license?.id } });
    }
  },
  process({ httpClient, action }, dispatch, done) {
    const { invitePayload, bannerLocation, licenseId } = action.payload;
    sendInvite(httpClient(dispatch), {...invitePayload })
      .then(resp => {
        dispatch(sendLicenseInviteSuccess(resp?.data));
        dispatch(setBannerState({ data: { type: 'success', message: intl.Success.Invitations.Invite_Success.replace(':email', invitePayload.invitee), location: (bannerLocation || '') } }));
      })
      .catch(() => {
        dispatch(sendLicenseInviteError());
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Invitations.Invite_Error.replace(':email', invitePayload.invitee), location: (bannerLocation || '') } }));
      })
      .finally(() => {
        dispatch(fetchPendingInvitesByLicense(licenseId));
        dispatch(reset(SHARE_ACCESS_BY_EMAIL_FORM));
        done();
      });
    }
});

export const sendGroupInviteLogic = createLogic({
  type: SEND_GROUP_INVITE,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const {
      organizationId,
      organizationName,
      adminName,
      groups,
      inviteeEmail,
      bannerLocation,
      errorCallback,
      successCallback
    } = action.payload;

    const state = getState();
    const organizations = state.app.getIn(['currentUser', 'linkedOrgs']).toJS();
    const organization = organizations?.find(org => org.organizationId === organizationId);
    const orgGroups = organization?.groups?.filter(orgGroup => groups?.some(payloadGroup => payloadGroup.groupId === orgGroup.id)) || [];
    const existMemberOfGroups = orgGroups.filter(og => og?.members?.find(member => member.email === inviteeEmail));

    const pendingInvitations = state.invitations.get('pendingOrganizationInvites').toJS().data;
    const existingGroupInvitations = pendingInvitations.filter(invite => {
      const isInvitee = invite.invitee === inviteeEmail;
      const groupIds = invite.groupId ? [invite.groupId] : invite?.meta?.groups?.map(g => g.groupId) || []; // need to handle groups[] and groupId on invites //
      const isExistingGroup = groups.find(g => groupIds.find(id => id === g.groupId));

      return isInvitee && isExistingGroup;
    });

    if (existMemberOfGroups?.length > 0) {
      const errorMessage = intl.Errors.Organizations.Add_User_To_Group_Duplicate_Member_Error;
      reject(
        setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', inviteeEmail), location: (bannerLocation || '') } })
      );
    } else if (existingGroupInvitations?.length > 0) {
      reject(
        resendInvite({
          existingGroupInvitations,
          invitee: inviteeEmail,
          lookupKey: organizationId,
          callbackUrl: INVITE_ORG_CALLBACK_URL,
          meta: {
            organizationId,
            organizationName,
            groups,
            adminName,
          },
          bannerLocation,
          successCallback,
          errorCallback,
        })
      );
    } else {
      allow(action);
    }
  },
  process({ httpClient, action }, dispatch, done) {
    const {
      organizationId,
      organizationName,
      groups,
      inviteeEmail,
      adminName,
      roleId,
      accountNumbers,
      requestingUser,
      bannerLocation,
      successCallback,
      errorCallback,
      formToReset
    } = action.payload;
    const convertRoleName = {
      'Administrator': 'Administrator',
      'Organization Billing Manager': 'Billing Manager',
      'Organization Purchaser': 'Purchaser',
      'Billing Account Purchaser': 'Purchaser'
    };
    const groupName = ROLES.find(role => role.roleId === roleId).name;
    const roleName = convertRoleName[groupName];
    const orgBillingAccountPurchaser = (accountNumbers?.length > 0) && ROLES?.find(role => role?.name === 'Billing Account Purchaser')?.roleId;

    const metaData = {
        organizationId,
        organizationName,
        adminName,
        roleId: roleId,
        subRoleId: orgBillingAccountPurchaser,
        roleName,
        accountNumbers: accountNumbers?.length > 0 && accountNumbers,
        requestingUser
      };

    sendInvite(httpClient(dispatch), {
      invitee: inviteeEmail,
      lookupKey: organizationId,
      callbackUrl: INVITE_ORG_CALLBACK_URL,
      meta: metaData
    })
      .then(res => {
        dispatch(sendGroupInviteSuccess(res.data));
        if (successCallback) successCallback();
      })
      .catch(error => {
        const errorMessage = intl.Errors.Organizations.Add_Role_To_User_Error;
        dispatch(setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', inviteeEmail), location: (bannerLocation || '') } }));
        dispatch(sendGroupInviteError(error));
        if (errorCallback) errorCallback();
      })
      .finally(() => {
        formToReset && dispatch(reset(formToReset));
        done();
      });
  },
});

export const resendInviteLogic = createLogic({
  type: RESEND_INVITE,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const {
      existingGroupInvitations,
      invitee,
      lookupKey,
      meta,
      callbackUrl,
      bannerLocation,
      successCallback,
      errorCallback,
    } = action.payload;
    const client = httpClient(dispatch);
    Promise.all(existingGroupInvitations.map(invite => cancelInvite(client, invite.inviteId)))
      .then(responses => {
        responses.forEach(res => dispatch(cancelInviteSuccess(res.data)));
      })
      .then(() => sendInvite(client, { invitee, lookupKey, meta, callbackUrl }))
      .then(res => {
        dispatch(resendInviteSuccess(res.data));
        dispatch(setBannerState({ data: { type: 'success', message: intl.Success.Invitations.Invite_Success.replace(':email', invitee), location: (bannerLocation || '') } }));
        if (successCallback) successCallback();
      })
      .catch(error => {
        const errorMessage = intl.Errors.Invitations.Resend_Invite_Error;
        dispatch(setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', invitee), location: (bannerLocation || '') } }));
        dispatch(resendInviteError(error));
        if (errorCallback) errorCallback();
      })
      .finally(() => done());
  },
});

export const respondLogic = createLogic({
  type: RESPOND_TO_INVITE,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { inviteId, response, email } = action.payload;
    const { successCallback } = action;
    const body = email && { email };
    httpClient(dispatch)
      .put(`/invitations/${inviteId}/${response}`, body)
      .then(() => {
        dispatch(respondToInviteSuccess(inviteId));
        if (successCallback) successCallback();
      })
      .catch(error => dispatch(respondToInviteError(inviteId, error)))
      .finally(() => done());
  }
});

export const cancelInvitePendingLogic = createLogic({
  type: cancelInvitePending().type,
  process({ httpClient, action }, dispatch, done) {
    const { inviteId, bannerLocation, email, successCallback } = action.payload;
    cancelInvite(httpClient(dispatch), inviteId)
      .then((res) => {
        if (successCallback) successCallback();
        dispatch(cancelInviteSuccess(res.data));
      })
      .catch(error => {
        if (error.response?.status === 400) {
          const errorMessage = intl.Errors.Invitations.Cancel_Invite_Accepted_Error;
          dispatch(setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', email), location: (bannerLocation || '') } }));
          // is this an anti-pattern to say success here?
          dispatch(cancelInviteSuccess(error.response.data.invitation));
        } else {
          const errorMessage = intl.Errors.Invitations.Cancel_Invite_Error;
          dispatch(setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', email), location: (bannerLocation || '') } }));
          dispatch(cancelInviteError(inviteId, error));
        }
      })
      .finally(() => done());
  }
});

export const cancelInviteConfirmationLogic = createLogic({
  type: cancelInviteConfirmation().type,
  latest: true,
  warnTimeout: 0,
  process({ action }, dispatch, done) {
    const { inviteId, email, bannerLocation, successCallback } = action?.payload;
    const modalProps = {
      show: true,
      message: <span><strong>{email}</strong> has already received an email invitation. If you cancel, this person can no longer accept the invite.</span>,
      okLabel: 'Cancel Invite',
      okDataHook: 'cancelInvite.ok',
      cancelLabel: 'Close',
      cancelDataHook: 'cancelInvite.close',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(cancelInvitePending({ inviteId, bannerLocation, email, successCallback }));
        dispatch(toggleModal());
        done();
      }
    };
    dispatch(triggerModal(modalProps));
  }
});

export default [
  fetchInvitesLogic,
  fetchInviteLogic,
  fetchPendingInvitesByOrganization,
  fetchPendingInvitationsByLicense,
  sendGroupInviteLogic,
  resendInviteLogic,
  sendLicenseInviteLogic,
  respondLogic,
  cancelInviteConfirmationLogic,
  cancelInvitePendingLogic
];
