import { createLogic } from 'redux-logic';
import { stopSubmit, setSubmitFailed } from 'redux-form';
import history from '../utils/history';
import {
  GET_PAYMENT_METHODS_PENDING,
  ADD_CREDIT_CARD_PENDING,
  UPDATE_CREDIT_CARD_PENDING,
  DELETE_CREDIT_CARD_PENDING,
  DELETE_CREDIT_CARD_CONFIRM,
  DELETE_PAYPAL_ACCOUNT_PENDING,
  DELETE_PAYPAL_ACCOUNT_CONFIRM,
  ASSIGN_DEFAULT_PAYMENT_METHOD_PENDING,
  PATH_PAYMENT_METHODS,
} from '../constants';
import {
  getPaymentMethodsSuccess,
  getPaymentMethodsError,
  setBannerState,
  addCreditCardSuccess,
  addCreditCardError,
  deleteCreditCardSuccess,
  deleteCreditCardError,
  deleteCreditCardPending,
  toggleModal,
  toggleForm,
  triggerModal,
  toggleProcessedItem,
  updateCreditCardSuccess,
  updateCreditCardError,
  getPaymentMethodsPending,
  deletePayPalAccountSuccess,
  deletePayPalAccountError,
  deletePayPalAccountPending,
  assignDefaultPaymentMethodSuccess,
  assignDefaultPaymentMethodError
} from '../actions/AppActions';
import intl from '../actions/lang/en.json';
import { cardType } from '../utils/creditCard';

export const formatCreditCards = creditCards =>
  creditCards?.map(creditCard => ({
    ...creditCard,
    cardType: creditCard.cardType || cardType(creditCard?.token)
  }));

export const filterPaypal = brainTreePaymentMethods =>
  brainTreePaymentMethods.filter(pm => pm.paymentType === 'PAYPAL');

export const findDefaultPaymentMethod = (defaultPaymentMethods = []) =>
  defaultPaymentMethods.find(
    paymentMethod => paymentMethod.channel?.toLowerCase() === 'lifeway'
  );

export const getPaymentMethodsLogic = createLogic({
  type: GET_PAYMENT_METHODS_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient(dispatch)
      .get('/payment-methods')
      .then(({ data }) => {
        const creditCards = formatCreditCards(
          data.creditCardPaymentMethods ?? []
        );
        const paypal = filterPaypal(data.brainTreePaymentMethods ?? []);
        const defaultPaymentMethod = findDefaultPaymentMethod(
          data.defaultPaymentMethods
        );

        dispatch(
          getPaymentMethodsSuccess(creditCards, paypal, defaultPaymentMethod)
        );

        action.meta?.onSuccess?.();
      })
      .catch(err => {
        const message = intl.Errors.PaymentMethods.Get_PaymentMethods_Error;
        const bannerState = {
          data: { type: 'error', message }
        };
        dispatch(setBannerState(bannerState));
        dispatch(getPaymentMethodsError(err));

        action.meta?.onError?.(err);
      })
      .finally(() => done());
  }
});

export const addCreditCardLogic = createLogic({
  type: ADD_CREDIT_CARD_PENDING,
  latest: true,
  process({ httpClient, action: { payload } }, dispatch, done) {
  const {
    values: {
      token,
      cardNumberMask,
      expirationMonth,
      expirationYear,
      displayName,
      cardHolderName,
      cardType,
      address
    },
    actions
  } = payload;
  const creditCard = {
    token,
    cardNumberMask,
    displayName,
    cardHolderName,
    expirationMonth: expirationMonth ? parseInt(expirationMonth) : null,
    expirationYear: expirationYear ? parseInt(expirationYear) : null,
    cardType,
    address: {
      type: address?.type,
      firstName: address?.firstName,
      lastName: address?.lastName,
      organizationName: address?.organizationName,
      line1: address?.line1,
      line2: address?.line2,
      city: address?.city,
      countrySubdivision: address?.countrySubdivision,
      postalCode: address?.postalCode,
      countryCode: address?.countryCode,
      phoneNumber: address?.phoneNumber
    }
  };
  httpClient(dispatch)
    .post('/credit-cards', creditCard)
    .then(res => {
      dispatch(addCreditCardSuccess(res.data));
      actions?.setSubmitting?.(false);
      history.push(
        PATH_PAYMENT_METHODS
      );
    })
    .catch(error => {
      actions?.setSubmitting?.(false);
      const isAddressSuggestionError = error?.response?.status === 406
      const errorMessage = isAddressSuggestionError
        ? intl.Errors.PaymentMethods.Add_Credit_Card_Address_Error
        : intl.Errors.PaymentMethods.Add_Credit_Card_Error
      dispatch(
        addCreditCardError({
          err: error?.response?.data ?? error,
          status: error?.response?.status
        })
      );
      dispatch(
        setBannerState({ data: { type: 'error', message: errorMessage } })
      );
    })
    .finally(() => {
      done();
    });
  }
});

export const updateCreditCardLogic = createLogic({
  type: UPDATE_CREDIT_CARD_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
      const {
        payload: {
          values: {
            cardHolderName,
            cardType,
            displayName,
            expirationMonth,
            expirationYear,
            address,
            id
          },
          actions
        },
      } = action;

      const creditCard = {
        id,
        cardHolderName,
        cardType,
        displayName,
        expirationMonth: expirationMonth ? parseInt(expirationMonth) : null,
        expirationYear: expirationYear ? parseInt(expirationYear) : null,
        address: {
          type: address?.type,
          firstName: address?.firstName,
          lastName: address?.lastName,
          organizationName: address?.organizationName,
          line1: address?.line1,
          line2: address?.line2,
          city: address?.city,
          countrySubdivision: address?.countrySubdivision,
          postalCode: address?.postalCode,
          countryCode: address?.countryCode,
          phoneNumber: address?.phoneNumber
        }
      };

      httpClient(dispatch)
        .put('/credit-cards', creditCard)
        .then(res => {
          dispatch(updateCreditCardSuccess(res.data));
          actions?.setSubmitting?.(false);
          history.push(
            PATH_PAYMENT_METHODS
          );
        })
        .catch(error => {
          actions?.setSubmitting?.(false);
          const isAddressSuggestionError = error?.response?.status === 406;

          const errorMessage = isAddressSuggestionError
            ? intl.Errors.PaymentMethods.Add_Credit_Card_Address_Error
            : intl.Errors.PaymentMethods.Add_Credit_Card_Error;

          dispatch(
            updateCreditCardError({
              err: error?.response?.data ?? error,
              status: error?.response?.status
            })
          );
          dispatch(
            setBannerState({ data: { type: 'error', message: errorMessage } })
          );
        })
        .finally(() => {
          done();
        });
  }
});

export const removeCreditCardLogic = createLogic({
  type: DELETE_CREDIT_CARD_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const id = action.payload.id;
    httpClient(dispatch)
      .delete(
        `/payment-methods/${id}`
      )
      .then(() => {
        history.push(
          PATH_PAYMENT_METHODS
        );
        dispatch(deleteCreditCardSuccess(id));
        dispatch(getPaymentMethodsPending());
        done();
      })
      .catch(error => {
        dispatch(
          deleteCreditCardError({
            err: error?.response?.data ?? error,
            status: error?.response?.status
          })
        );
        dispatch(
          setBannerState({
            data: {
              type: 'error',
              message: intl.Errors.PaymentMethods.Delete_Card_Error
            }
          })
        );
        done();
      });
  }
});

export const removePayPalAccountLogic = createLogic({
  type: DELETE_PAYPAL_ACCOUNT_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const id = action.payload.id;

    httpClient(dispatch)
      .delete(`/payment-methods/${id}`)
      .then(() => {
        dispatch(deletePayPalAccountSuccess(id));
        dispatch(toggleProcessedItem());
        done();
      })
      .catch(error => {
        dispatch(
          deletePayPalAccountError({
            err: error?.response?.data ?? error,
            status: error?.response?.status
          })
        );
        dispatch(
          setBannerState({
            data: {
              type: 'error',
              message: intl.Errors.PaymentMethods.Delete_PayPal_Error
            }
          })
        );
        dispatch(toggleProcessedItem());
        done();
      });
  }
});

export const deleteCreditCardConfirmLogic = createLogic({
  type: DELETE_CREDIT_CARD_CONFIRM,
  latest: true,
  warnTimeout: 0,
  process({ action }, dispatch, done) {
    const { id, cardSubscriptions, cardAutoships, cardLogo, lastFour } = action.payload;
    const modalProps = {
      show: true,
      subscriptions: cardSubscriptions,
      autoships: cardAutoships,
      id: id,
      cardLogo: cardLogo,
      lastFour: lastFour,
      message: `${intl.Errors.PaymentMethods.Delete_Warning_Credit_Card}`,
      okLabel: 'Yes',
      okDataHook: 'deleteCreditCardYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'deleteCreditCardNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(toggleProcessedItem(id));
        dispatch(deleteCreditCardPending({ id }));
        dispatch(toggleModal());
        done();
      },
      cancelClick: () => {
        dispatch(toggleModal());
        history.push(
          PATH_PAYMENT_METHODS
        );
        done();
      }
    };
    dispatch(toggleForm(null));
    dispatch(triggerModal(modalProps));
  }
});

export const deletePayPalAccountConfirmLogic = createLogic({
  type: DELETE_PAYPAL_ACCOUNT_CONFIRM,
  latest: true,
  warnTimeout: 0,
  process({ action }, dispatch, done) {
    const { id, email } = action.payload;
    const modalProps = {
      show: true,
      message: `${intl.Errors.PaymentMethods.Delete_Warning_PayPal} ${email}?`,
      okLabel: 'Yes',
      okDataHook: 'deletePayPalYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'deletePayPalNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(toggleProcessedItem(id));
        dispatch(deletePayPalAccountPending({ id }));
        dispatch(toggleModal());
        done();
      },
      cancelClick: () => {
        dispatch(toggleModal());
        done();
      }
    };
    dispatch(toggleForm(null));
    dispatch(triggerModal(modalProps));
  }
});

export const assignDefaultPaymentMethodLogic = createLogic({
  type: ASSIGN_DEFAULT_PAYMENT_METHOD_PENDING,
  latest: true,
  process({ httpClient, action: { payload } }, dispatch, done) {
    const { paymentMethodId, paymentMethodType } = payload;
    const request = { paymentMethodId, paymentMethodType, channel: 'lifeway' };

    dispatch(toggleProcessedItem(paymentMethodId));

    httpClient(dispatch)
      .post('/payment-methods/default', request)
      .then(res => {
        dispatch(assignDefaultPaymentMethodSuccess(res.data));
      })
      .catch(error => {
        dispatch(
          assignDefaultPaymentMethodError({
            err: error?.response?.data ?? error,
            status: error?.response?.status
          })
        );
        dispatch(
          setBannerState({
            data: {
              type: 'error',
              message: intl.Errors.PaymentMethods.Assign_Default_Error
            }
          })
        );
      })
      .finally(() => {
        dispatch(toggleProcessedItem());
        done();
      });
  }
});

export function expirationDateToInt(payload) {
  const toNumber = val => val && ~~val;
  const { expirationMonth, expirationYear } = payload;
  return {
    ...payload,
    expirationMonth: expirationMonth && toNumber(expirationMonth),
    expirationYear: expirationYear && toNumber(expirationYear)
  };
}

export default [
  getPaymentMethodsLogic,
  addCreditCardLogic,
  deleteCreditCardConfirmLogic,
  removeCreditCardLogic,
  updateCreditCardLogic,
  removePayPalAccountLogic,
  deletePayPalAccountConfirmLogic,
  assignDefaultPaymentMethodLogic
];
