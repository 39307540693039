import React from 'react';
import { AccountLink } from './SideNavOrg';
import { ORG_PATHS } from '../constants';
import { injectParams } from '../utils/pathHelpers';

const BillingAccountsDropdown = ({ setActiveBillingAccount, billingAccount, pathname, organizationId }) => {

  if(!billingAccount) {
    return null
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '12px' }}>
      <AccountLink
        key={billingAccount.id}
        to={injectParams(ORG_PATHS.BILLING_ACTIVITY, {
          organizationId,
          billingAccountId: billingAccount.id
        })}
        style={{
          fontSize: '14px',  
          color: pathname.includes(billingAccount.id) ? '#414042' : '#476692',
          fontWeight: pathname.includes(billingAccount.id) ? 'bold' : 'normal'
        }}
        data-qa-hook={`accountActivity.link.${billingAccount.id}`}
        onClick={() => setActiveBillingAccount(billingAccount.id)}
        activeLink={pathname.includes(billingAccount.id)}
      >
        Acct {billingAccount.accountNumber}
      </AccountLink>
    </div>
  )
};

export default BillingAccountsDropdown;