import React from 'react';
import {
  getLinkedOrganizationsPending,
  getLinkedOrganizationsSuccess,
  getLinkedOrganizationsError,
  setBannerState,
  getLinkedOrganizationGroupsPending,
  getLinkedOrganizationGroupsSuccess,
  getLinkedOrganizationGroupsError,
  claimLinkedOrganizationAdminPending,
  claimLinkedOrganizationAdminSuccess,
  claimLinkedOrganizationAdminError,
  addUserToOrganizationGroupPending,
  addUserToOrganizationGroupSuccess,
  addUserToOrganizationGroupError,
  removeUserFromOrganizationGroupConfirm,
  removeUserFromOrganizationGroupPending,
  removeUserFromOrganizationGroupSuccess,
  removeUserFromOrganizationGroupError,
  unlinkOrganizationPending,
  unlinkOrganizationSuccess,
  unlinkOrganizationError,
  toggleProcessedItem,
  toggleModal,
  toggleForm,
  triggerModal,
  revalidateOrgLinkWithPinPending,
  revalidateOrgLinkWithPinSuccess,
  revalidateOrgLinkWithPinError,
  getOrganizationMemberRolesPending,
  getOrganizationMemberRolesSuccess,
  getOrganizationMemberRolesError,
  addUserToOrganizationRolePending,
  addUserToOrganizationRoleSuccess,
  removeRoleFromUserConfirm,
  removeRoleFromUserPending,
  removeRoleFromUserSuccess,
  removeRoleFromUserError,
  addUserToOrganizationRoleError,
  getCustomerPermissionsPending,
  getCustomerPermissionsSuccess,
  getUserRolesPending,
  getUserRolesSuccess,
  getUserRolesError
} from '../actions/AppActions';
import { createLogic } from 'redux-logic';
import { formatAccounts, sortAlphabetically } from '../utils/profileHelpers';
import { combinePurchasersGroups, removeBillingAccountGroups, formatGroups, formatOrgMemberRoles } from '../utils/orgHelpers';
import intl from '../actions/lang/en.json';
import { ENABLE_REFACTORED_PAGINATED_ORGS, INVITE_APPROVED_PURCHASER_PENDING, PATH_PROFILE, PERMISSIONS, REMOVE_APPROVED_PURCHASER_PENDING, ROLES, UNLINK_ORG_CONFIRM } from '../constants';
import history from '../utils/history';
import { getOrganization } from '../selectors/OrgManagementSelectors';

export const DEFAULT_PAGE_SIZE = 100;

const getPaginatedOrgs = async (
  action,
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  const { data: orgs } = await action({ page, pageSize });
  if (orgs.data && page < orgs.totalPages - 1) {
    const nextPageData = await getPaginatedOrgs(action, page + 1, pageSize);
    return [...orgs.data, ...nextPageData];
  }

  return orgs.data;
};

const getLegacyPaginatedOrgs = async(
  action,
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE) => {
  const { data: orgs, headers } = await action({ page, pageSize });
  const totalOrgs = headers?.totalElements;

  if (
    orgs != null &&
    ((totalOrgs && totalOrgs > (page + 1) * pageSize) ||
      (!totalOrgs && orgs.length === pageSize))
  ) {
    const rest = await getLegacyPaginatedOrgs(action, page + 1, pageSize);
    return [...orgs, ...rest];
  } else {
    return orgs;
  }
};

export const getOrganizationsLogic = createLogic({
  type: getLinkedOrganizationsPending().type,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { userId } = action?.payload;
    const apiCall = params => httpClient(dispatch).get('/organizations', { params });
    const fetchOrgs = ENABLE_REFACTORED_PAGINATED_ORGS ? getPaginatedOrgs : getLegacyPaginatedOrgs;

    return fetchOrgs(apiCall)
      .then(orgs => {
        dispatch(getLinkedOrganizationsSuccess(sortAlphabetically(formatAccounts(formatGroups(orgs, userId)))));
        dispatch(getCustomerPermissionsPending({orgs, customerId: userId}));
        done();
      })
      .catch(error => {
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Get_Linked_Orgs_Error } }));
        dispatch(getLinkedOrganizationsError(error));
        done();
      });
  }
});

export const getOrganizationGroupsLogic = createLogic({
  type: getLinkedOrganizationGroupsPending().type,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { organizationId } = action?.payload;

    return httpClient(dispatch).get(`/organizations/${organizationId}/groups`)
    .then(resp => {
      dispatch(getLinkedOrganizationGroupsSuccess({
        organizationId,
        groups: combinePurchasersGroups(resp.data)?.filter(removeBillingAccountGroups)
      }));
      done();
    }).catch(error => {
      dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Get_Linked_Org_Groups_Error } }));
      dispatch(getLinkedOrganizationGroupsError(error));
      done();
    });
  }
});

export const getOrganizationMemberRolesLogic = createLogic({
  type: getOrganizationMemberRolesPending().type,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
    const {userIds, organizationId} = action?.payload;
	const state = getState();
    return httpClient(dispatch).post('/customer/roles', {customerIds: userIds})
    .then(resp => {
      const formattedMemberRoles = formatOrgMemberRoles(state, resp.data, organizationId);
      dispatch(getOrganizationMemberRolesSuccess(formattedMemberRoles));
      done();
    })
	.catch(error => {
      dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Get_Linked_Org_Member_Roles_Error } }));
      dispatch(getOrganizationMemberRolesError(error));
      done();
    });
  }
});

export const getUserRolesLogic = createLogic({
  type: getUserRolesPending().type,
  process({ httpClient, getState, action }, dispatch, done) {
    const { customerId } = action?.payload;
    return httpClient(dispatch).get(`/organization/customers/${customerId}/roles`)
    .then(resp => {
      dispatch(getUserRolesSuccess(resp.data));
      done();
    })
	.catch(error => {
      dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Get_User_Roles_Error } }));
      dispatch(getUserRolesError(error));
      done();
    });
  }
});

const createPermissionObject = (permissionId, organizationId, account) => {
  const permissionObject = {
    permissionId,
    permissionScope: {
        organization: organizationId,
    }
  };

  if(account) {
    permissionObject.permissionScope['account'] = account;
  }

  return permissionObject;
};

const getCustomerPermissionsPayload = (orgs, customerId) => {
  const permissionArray = orgs.flatMap(org =>
    PERMISSIONS.map(permission => {
      const permissionsWithAccountNumbers = ['PayWithACH', 'ViewTransactionHistory', 'ManageACHPaymentMethods'];

      if (permissionsWithAccountNumbers.includes(permission.displayId) && org?.organizationProfile.billingAccounts) {
        return org?.organizationProfile.billingAccounts.map(account =>
          createPermissionObject(permission.permissionId, org.organizationId, account.accountNumber)).flat();
      }

      return createPermissionObject(permission.permissionId, org.organizationId);
    })
  );

  return {
    customerId,
    queryPermissions: permissionArray.flat(),
  };
};

const getPermissions = async(action, page = 0, pageSize = DEFAULT_PAGE_SIZE) => {
  const { data: permissions } = await action({ page, pageSize });
  if (permissions.content && page < permissions.totalPages - 1) {
    const nextPageData = await getPermissions(action, page + 1, pageSize);
    return [...permissions.content, ...nextPageData];
  }

  return permissions.content;
};

export const getCustomerPermissionsLogic = createLogic({
  type: getCustomerPermissionsPending().type,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
    const { customerId, orgs } = action.payload;
    const customerPermissions = getCustomerPermissionsPayload(orgs, customerId);
    const permissionCall = params => httpClient(dispatch).post(`/organization/customer/permissions?page=${params?.page}&size=${params?.pageSize}`, {
      ...customerPermissions,
  });

    return getPermissions(permissionCall)
    .then(resp => {
      dispatch(getCustomerPermissionsSuccess(resp));
      done();
    })
    .catch(error => {
      dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Get_Customer_Permissions_Error } }));
      dispatch(getOrganizationMemberRolesError(error));
      done();
    });
  }
});

export const unlinkOrganizationConfirmationLogic = createLogic({
  type: UNLINK_ORG_CONFIRM,
  latest: true,
  processOptions: {
    dispatchMultiple: true,
  },
  warnTimeout: 0,
  process({ action }, dispatch) {
    const { organizationName, organizationId, userCanUnlink } = action.payload;
    const unlinkMessage = `You are about to unlink ${organizationName}. You will no longer be able to make purchases on behalf of this organization or view this profile. Do you want to unlink?`;
    const addAdminMessage = 'You must add another administrator before you can unlink this organization.';
    const modalProps = userCanUnlink
      ? {
        show: true,
        message: unlinkMessage,
        okLabel: 'Yes',
        okDataHook: 'unlinkOrg.yes',
        cancelLabel: 'No',
        cancelDataHook: 'unlinkOrg.no',
        buttonOrder: 'rtl',
        okClick: () => {
          dispatch(toggleProcessedItem(organizationId));
          dispatch(unlinkOrganizationPending(organizationId));
          dispatch(toggleModal());
        },
        cancelClick: () => {
          history.push(PATH_PROFILE);
          dispatch(toggleModal());
        }
      }
      : {
        show: true,
        message: addAdminMessage,
        okLabel: 'Go to Groups',
        okDataHook: 'unlinkOrg.goToGroups',
        cancelLabel: 'Go Back',
        cancelDataHook: 'unlinkOrg.goBack',
        buttonOrder: 'rtl',
        okClick: () => {
          history.push(`/organization/${organizationId}/groups`);
          dispatch(toggleModal());
        },
        cancelClick: () => {
          history.push(PATH_PROFILE);
          dispatch(toggleModal());
        }
      };
    dispatch(toggleForm(null));
    dispatch(triggerModal(modalProps));
  }
});

export const unlinkOrganizationLogic = createLogic({
  type: unlinkOrganizationPending().type,
  latest: true,
  process({ httpClient, action, getState}, dispatch, done) {
    const { callbackFn, payload } = action;
    const organizationId = payload?.organizationId;
    return httpClient(dispatch)
      .delete(`/organizations/${organizationId}`)
      .then(() => {
        dispatch(unlinkOrganizationSuccess(organizationId));
        if (callbackFn) callbackFn();
      })
      .catch(error => {
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Unlink_Organization_Error } }));
        dispatch(unlinkOrganizationError(error));
      })
      .finally(() => done());
  }
});

export const claimOrganizationAdminLogic = createLogic({
  type: claimLinkedOrganizationAdminPending().type,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { orgs, organizationId, userIds, userId, errorCallbackFn, bannerLocation } = action?.payload;

    return httpClient(dispatch).post(`/organizations/${organizationId}/admin`, {})
    .then(({ data }) => {
      const { adminGroupId } = data?.data;
      dispatch(getLinkedOrganizationGroupsPending(organizationId));
      dispatch(claimLinkedOrganizationAdminSuccess({ organizationId, adminGroupId }));
      setTimeout(() => {
        dispatch(getCustomerPermissionsPending({orgs, customerId: userId}))
        dispatch(getOrganizationMemberRolesPending({userIds: userIds, organizationId}))
        done();
      }, 2000);
    })
    .catch(error => {
      dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Claim_Linked_Org_Admin_Error, location: (bannerLocation || '') } }));
      dispatch(claimLinkedOrganizationAdminError(error));
      if (errorCallbackFn) errorCallbackFn();
      done();
    });
  }
});

export const addUserToOrganizationGroupLogic = createLogic({
  type: addUserToOrganizationGroupPending().type,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const {
        email, organizationId, bannerLocation, groups
    } = action.payload;

    const state = getState();
    const organizations = state.app.getIn(['currentUser', 'linkedOrgs']).toJS();
    const organization = organizations?.find(org => org.organizationId === organizationId);
    const orgGroups = organization?.groups?.filter(orgGroup => groups?.some(payloadGroup => payloadGroup.groupId === orgGroup.id)) || [];
    const existMemberOfGroups = orgGroups.filter(og => og?.members?.find(member => member.email === email));

    if (existMemberOfGroups?.length > 0) {
        const errorMessage = intl.Errors.Organizations.Add_User_To_Group_Duplicate_Member_Error;
        reject(
          setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', email), location: (bannerLocation || '') } })
        );
      } else {
        allow(action);
      }
},
  process({ httpClient, action }, dispatch, done) {
    const { email, organizationId, groupId, errorCallbackFn, successCallbackFn, bannerLocation } = action?.payload;

    return httpClient(dispatch).post(`/organizations/${organizationId}/groups/${groupId}/users`, { email })
      .then(resp => {
        dispatch(addUserToOrganizationGroupSuccess(resp.data));
        dispatch(getLinkedOrganizationGroupsPending(organizationId));
        if (successCallbackFn) successCallbackFn();
        done();
      })
      .catch(error => {
        let errorMessage;
        if (error?.response?.data?.errorCode === 'email.username.not.found') {
         errorMessage = intl.Errors.Organizations.Add_User_To_Group_Missing_Lifeway_Account_Error;
        } else if (error?.response?.data?.errorCode === 'duplicate.member') {
         errorMessage = intl.Errors.Organizations.Add_User_To_Group_Duplicate_Member_Error;
        } else {
         errorMessage = intl.Errors.Organizations.Add_User_To_Group_Error;
        }
        dispatch(setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', email), location: (bannerLocation || '') } }));
        dispatch(addUserToOrganizationGroupError(error));
        if (errorCallbackFn) errorCallbackFn();
        done();
      });
  }
});

function buildAddRoleCommand(customerId, roleId, organizationId, accountNumber, requestingUser){
    const roleCommand = {
        'command': {
          AddRoleToCustomer: {
            aggregateID: customerId,
            roleId: roleId,
            scopeVariables: {
              'organization': organizationId,
              'group': '*'
            },
            requestingContext: {
              requestingDomain: 'MLC',
              requestingUserId: requestingUser,
              requestingOrganizationId: organizationId
            },
          }
        }
      };

    if (accountNumber) {
      roleCommand.command.AddRoleToCustomer.scopeVariables['account'] = accountNumber;
    } else {
      roleCommand.command.AddRoleToCustomer.scopeVariables['account'] = '*';
    }

    return roleCommand;
}

const formatRoleName = {
    'Administrators': 'administrator',
    'Purchasers': 'organizationPurchaser',
    'Billing Managers': 'organizationBillingManager'
};

export const addUserToOrganizationRoleLogic = createLogic({
  type: addUserToOrganizationRolePending().type,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const {
      email, bannerLocation, groupName, accountNumber
    } = action.payload;
    const state = getState();
    const orgMemberRoles = state.app.getIn(['currentUser', 'linkedOrgsMemberRoles']).toJS();
    const formattedRole = formatRoleName[groupName];
    const roleMembers = orgMemberRoles[formattedRole];
    const memberHasRole = roleMembers.some(memberRole => memberRole.email === email);

    if (memberHasRole && !accountNumber){
        const errorMessage = intl.Errors.Organizations.Add_Role_To_User_Duplicate_Role_Error;
        reject(
          setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', email), location: (bannerLocation || '') } })
        );
      } else {
        allow(action);
      }
},
process({ httpClient, action, getState, delay = 2000 }, dispatch, done) {
  const { email, organizationId, roleId, accountNumber, accountNumbers, requestingUser, errorCallbackFn, successCallbackFn, bannerLocation } = action?.payload;

  const state = getState();
  const orgMemberRoles = state.app.getIn(['currentUser', 'linkedOrgsMemberRoles']).toJS();
  const organizationMembers = orgMemberRoles['organizationMembers'];
  const subRoleId = (accountNumber || accountNumbers) ? ROLES.find(role => role.name === 'Billing Account Purchaser')?.roleId : null;
  const memberInfo = organizationMembers.find(memberRole => memberRole.email === email);
  const commandRoleId = (!accountNumber) ? roleId : subRoleId;
  const org = getOrganization(state, organizationId);
  const billingAccounts = org ? org.groups.find(group => group.name === 'Purchasers')?.billingAccountSubGroups : [];

  const postRole = (roleId, accountNumber) => {
    const roleCommand = buildAddRoleCommand(memberInfo.customerId, roleId, organizationId, accountNumber, requestingUser);
    return httpClient(dispatch).post('/roles', roleCommand);
  };

  const rolePromises = (Array.isArray(accountNumbers) && accountNumbers.length > 1)
  ? [...accountNumbers.map(accountNumber => postRole(subRoleId, accountNumber)), postRole(roleId, accountNumber)]
  : [postRole(commandRoleId, accountNumber)];

  return Promise.all(rolePromises)
    .then((res) => {
        dispatch(addUserToOrganizationRoleSuccess({roleId, subRoleId, customerInfo: memberInfo, accountNumber, accountNumbers, billingAccounts, organizationId, updatedMember: res}));
        if (successCallbackFn) successCallbackFn();
        done();
    })
    .catch(error => {
      let errorMessage;
      if (error?.response?.data?.errorCode === 'email.username.not.found') {
          errorMessage = intl.Errors.Organizations.Add_User_To_Group_Missing_Lifeway_Account_Error;
      } else if (error?.response?.data?.errorCode === 'duplicate.member') {
          errorMessage = intl.Errors.Organizations.Add_User_To_Group_Duplicate_Member_Error;
      } else {
          errorMessage = intl.Errors.Organizations.Add_User_To_Group_Error;
      }
      dispatch(setBannerState({ data: { type: 'error', message: errorMessage.replace(':email', email), location: (bannerLocation || '') } }));
      dispatch(addUserToOrganizationRoleError(error));
      if (errorCallbackFn) errorCallbackFn();
      done();
    });
}
});

export const removeRoleConfirmLogic = createLogic({
  type: removeRoleFromUserConfirm().type,
  latest: true,
  processOptions: {
    dispatchMultiple: true,
  },
  warnTimeout: 0,
  process({ action }, dispatch) {
    const { organizationId, groupId, roleInfo, roleId, userId, requestingUser, groupName, personId, name, email, bannerLocation, userCannotRemoveMember, openAddMembersForm, successCallbackFn } = action?.payload;
    const MessageCmpt = () => (
      <span>
        {userCannotRemoveMember ?
          <>You must add another adminstrator before you can remove {name}.</>
        : <>You are about to remove the {roleInfo?.name} role from {name} ({email}).<br />Are you sure?</>
        }
      </span>
  );

    const modalProps = userCannotRemoveMember
    ? {
        show: true,
        message: <MessageCmpt />,
        okLabel: 'Add Administrator',
        okDataHook: 'removeUserGroup.goBack',
        buttonOrder: 'rtl',
        okClick: () => {
          dispatch(toggleModal());
          dispatch(openAddMembersForm());
        }
      }
    : {
        show: true,
        message: <MessageCmpt />,
        okLabel: 'Yes',
        okDataHook: 'removeUserGroup.yes',
        cancelLabel: 'No',
        cancelDataHook: 'removeUserGroup.no',
        buttonOrder: 'rtl',
        okClick: () => {
          dispatch(removeRoleFromUserPending({ organizationId, roleId, roleInfo, userId, requestingUser, groupName, groupId, personId, email, bannerLocation, successCallbackFn }));
          dispatch(toggleModal());
        },
        cancelClick: () => {
          dispatch(toggleModal());
        }
    };
    dispatch(triggerModal(modalProps));
  }
});

function buildRemoveRoleCommand(customerId, roleId, organizationId, requestingUser) {
    const roleCommand = {
        'command': {
          RemoveRoleFromCustomer: {
            aggregateID: customerId,
            assignedRoleId: roleId,
            requestingContext: {
              requestingDomain: 'MLC',
              requestingUserId: requestingUser,
              requestingOrganizationId: organizationId
            },
          }
        }
      };

    return roleCommand;
}

export const removeRoleLogic = createLogic({
    type: removeRoleFromUserPending().type,
    latest: true,
    process({ httpClient, action }, dispatch, done) {
      const { organizationId, userId, roleId, accountNumber, requestingUser } = action?.payload;
      const { email, bannerLocation, successCallbackFn, roleInfo } = action?.payload;
      const roleCommand = buildRemoveRoleCommand(userId, roleId, organizationId, requestingUser);

      return httpClient(dispatch).post('/roles', roleCommand)
        .then(() => {
          dispatch(removeRoleFromUserSuccess({customerId: userId, roleId: roleInfo?.roleId, accountNumber}));
          if (successCallbackFn) successCallbackFn();
        })
        .catch(error => {
          dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Remove_Role_From_User_Error.replace(':email', email), location: (bannerLocation || '') } }));
          dispatch(removeRoleFromUserError(error));
          done();
        });
    }
});

export const removeUserFromOrganizationGroupConfirmationLogic = createLogic({
  type: removeUserFromOrganizationGroupConfirm().type,
  latest: true,
  warnTimeout: 0,
  process({ action }, dispatch, done) {
    const { organizationId, groupId, personId, name, email, bannerLocation, userCannotRemoveMember, openAddMembersForm, successCallbackFn } = action?.payload;

    const MessageCmpt = () => (
      <span>
        {userCannotRemoveMember ?
          <>You must add another adminstrator before you can remove {name}.</>
        : <>You are about to remove {name} ({email}) from this group.<br />Are you sure?</>
        }
      </span>
  );

    const modalProps = userCannotRemoveMember
    ? {
        show: true,
        message: <MessageCmpt />,
        okLabel: 'Add Administrator',
        okDataHook: 'removeUserGroup.goBack',
        buttonOrder: 'rtl',
        okClick: () => {
          dispatch(toggleModal());
          dispatch(openAddMembersForm());
          done();
        }
      }
    : {
        show: true,
        message: <MessageCmpt />,
        okLabel: 'Yes',
        okDataHook: 'removeUserGroup.yes',
        cancelLabel: 'No',
        cancelDataHook: 'removeUserGroup.no',
        buttonOrder: 'rtl',
        okClick: () => {
          dispatch(removeUserFromOrganizationGroupPending({ organizationId, groupId, personId, email, bannerLocation, successCallbackFn }));
          dispatch(toggleModal());
          done();
        },
        cancelClick: () => {
          dispatch(toggleModal());
          done();
        }
    };
    dispatch(triggerModal(modalProps));
  }
});

export const removeUserFromOrganizationGroupLogic = createLogic({
  type: removeUserFromOrganizationGroupPending().type,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { organizationId, groupId, personId } = action?.payload;
    const { email, bannerLocation, successCallbackFn } = action?.payload;

    return httpClient(dispatch).delete(`/organizations/${organizationId}/groups/${groupId}/users/${personId}`)
      .then(resp => {
        dispatch(removeUserFromOrganizationGroupSuccess({ personId, organizationId, groupId }));
        dispatch(getLinkedOrganizationGroupsPending(organizationId));
        if (successCallbackFn) successCallbackFn();
      })
      .catch(error => {
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Remove_User_From_Group_Error.replace(':email', email), location: (bannerLocation || '') } }));
        dispatch(removeUserFromOrganizationGroupError(error));
      }).finally(() => done());
  }
});

export const relinkBillingAccountLogic = createLogic({
  type: revalidateOrgLinkWithPinPending().type,
  latest: true,
  process({ httpClient, action: { payload } }, dispatch, done) {
    const { accountNumber, pin, id, errorCallback } = payload;
    httpClient(dispatch).put('/linked-billing-accounts',
      { accountNumber, pin, billingAccountLinkId: id }
    ).then(res => {
      dispatch(revalidateOrgLinkWithPinSuccess(res.data));
    }).catch(error => {
      dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.Organizations.Revalidate_Pin_Error } }));
      dispatch(revalidateOrgLinkWithPinError(error));
      errorCallback && errorCallback(intl.Errors.Organizations.Revalidate_Pin_Error);
    }).finally(() => done());
  }
});

export const inviteApprovedPurchaserLogic = createLogic({
  type: INVITE_APPROVED_PURCHASER_PENDING,
  latest: true,
  process({ httpClient, action: { payload } }, dispatch, done) {
    const {organizationId, accountId, name, externalReferenceId, onSuccess, onError} = payload;
    httpClient(dispatch).delete(
      `/organizations/${organizationId}/billing-accounts/${accountId}/legacy-approved-purchaser/${externalReferenceId}`
    )
    .then(res => {
      onSuccess?.();
      done();
    })
    .catch(error => {
      onError?.(error);
      const errorMessage = intl.Errors.Organizations.Invite_Approved_Purchaser_Error;
      dispatch(
        setBannerState({
          data: {
            type: 'error',
            message: errorMessage.replace(':name', name),
            location: 'Approved Purchasers Prompt'
          }
        })
      );
      done();
    });
  }
});

export const removeApprovedPurchaserLogic = createLogic({
  type: REMOVE_APPROVED_PURCHASER_PENDING,
  latest: true,
  process({ httpClient, action: { payload } }, dispatch, done) {
    const { organizationId, accountId, externalReferenceId, onSuccess, name, onError } = payload;
    httpClient(dispatch)
      .delete(
        `/organizations/${organizationId}/billing-accounts/${accountId}/legacy-approved-purchaser/${externalReferenceId}`
      )
      .then(res => {
        onSuccess?.();
        done();
      }).catch(error => {
        onError?.(error);
        const errorMessage = intl.Errors.Organizations.Remove_Approved_Purchaser_Error;
        dispatch(
          setBannerState({
            data: {
              type: 'error',
              message: errorMessage.replace(':name', name),
              location: 'Approved Purchasers Prompt'
            }
          })
        );
        done();
      });
  }
});

export default [
  getOrganizationsLogic,
  getOrganizationGroupsLogic,
  claimOrganizationAdminLogic,
  addUserToOrganizationGroupLogic,
  removeUserFromOrganizationGroupConfirmationLogic,
  removeUserFromOrganizationGroupLogic,
  unlinkOrganizationConfirmationLogic,
  unlinkOrganizationLogic,
  relinkBillingAccountLogic,
  inviteApprovedPurchaserLogic,
  removeApprovedPurchaserLogic,
  getOrganizationMemberRolesLogic,
  addUserToOrganizationRoleLogic,
  removeRoleConfirmLogic,
  removeRoleLogic,
  getCustomerPermissionsLogic,
  getUserRolesLogic,
];
