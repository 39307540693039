import { createLogic } from 'redux-logic';
import { LOCATION_CHANGE } from 'react-router-redux';
import { toggleForm } from '../actions/AppActions';
import {
  PATH_ADD_CREDIT_CARD,
  PATH_PAYMENT_METHODS,
  PATH_CREDIT_CARDS
} from '../constants';

export const paymentFormsToggleLogic = createLogic({
  type: LOCATION_CHANGE,
  latest: true,
  warnTimeout: 0,
  process({ action, getState }, dispatch) {
    if (window.navigateAway) return true;
    const pathname = action.payload.location.pathname;

    switch (pathname) {
      case PATH_CREDIT_CARDS:
        dispatch(toggleForm(null));
        break;

      case PATH_PAYMENT_METHODS:
        dispatch(toggleForm(null));
        break;

      case PATH_ADD_CREDIT_CARD:
        dispatch(toggleForm(pathname));
        break;

      default:
        return false;
    }
    return false;
  }
});

export default [
  paymentFormsToggleLogic
];
