import React from 'react';
import { PATH_REDEEM_CODE } from '../constants';
import { OutlineButton } from './SecondaryButton';

export const RedeemButton = (props) => {
  const { style } = props;

  return (
    <OutlineButton
      id="redeem_code_link"
      to={PATH_REDEEM_CODE}
      data-qa-hook={props.dataHook ? props.dataHook : 'redeemCode.link'}
      title="Redeem Code"
      style={{ ...style, justifyContent: 'center' }}
      {...props}
    >
      Redeem Code
    </OutlineButton>
  );
};
