import { createLogic } from 'redux-logic';
import { TABLE_ON_INIT_COLUMNS, TABLE_UPDATE_SETTINGS } from '../constants';
import { shouldFetchResults, getNextPage } from '../utils/tables';
import {
  getBillingIdFromPathname, getObjectByTableName, getBillingAccountById
} from '../utils/orderHelpers';
import {
  getProcessedOrdersPending, getPendingOrdersPending, getOrderSearchPending,
  getAccountTransactionsPending,
  getOrdersPending
} from '../actions/AppActions';

export const onInitColumnsLogic = createLogic({
  type: TABLE_ON_INIT_COLUMNS,
  latest: true,

  transform({ action }, next) {
    const columns = [];
    action.payload.config.columns.forEach((col) => {
      const columnConfig = {
        property: col.prop,
        header: {
          label: col?.label,
          props: { style: col.width ? { width: col.width } : {}, colSpan: col.colSpan },
          formatters: col.formatHeader ? [col.formatHeader] : []
        },
        cell: {
          props: { style: {}, colSpan: col.colSpan },
          formatters: col.formatCell ? [col.formatCell] : []
        },
        visible: !col.hidden
      };
      columns.push(columnConfig);
    });
    next({ payload: { columns, tableName: action.payload.tableName, tableIndex: action.payload.tableIndex }, type: action.type });
  }
});

export const appendRowDataLogic = createLogic({
  type: TABLE_UPDATE_SETTINGS,
  latest: true,
  process({ action, getState }, dispatch, done) {
    let rows;
    let index;
    let formName;
    let updateAction;
    let orderObject;
    let actionPayload;
    let account;
    let updateTableOnly;

    const { tableName, settings, fromDate, toDate } = action.payload;
    const keys = Object.keys(settings);
    // return if only settings change is loading status
    if (keys.indexOf('isLoading') > -1) return false;

    const state = getState();
    const billingId = getBillingIdFromPathname(location.pathname);
    const tableSettings = state.app.getIn(['tableSettings', tableName]);

    switch (tableName) {
      case 'orders':
      case 'orgOrders':
        orderObject = getObjectByTableName(tableName);
        index = billingId ? [orderObject, billingId, 'rows'] : [orderObject, 'rows'];
        rows = state.app.orders.getIn(index);
        updateAction = getOrdersPending;
        actionPayload = { billingId, page: getNextPage(tableSettings) };
        break;
      case 'processedOrders':
      case 'processedOrdersOrg':
        orderObject = getObjectByTableName(tableName);
        index = billingId ? [orderObject, billingId, 'rows'] : [orderObject, 'rows'];
        rows = state.app.orders.getIn(index);
        updateAction = getProcessedOrdersPending;
        actionPayload = { billingId, page: getNextPage(tableSettings) };
        break;

      case 'pendingOrders':
      case 'pendingOrdersOrg':
        orderObject = getObjectByTableName(tableName);
        index = billingId ? [orderObject, billingId, 'rows'] : [orderObject, 'rows'];
        rows = state.app.orders.getIn(index);
        updateAction = getPendingOrdersPending;
        actionPayload = { billingId, page: getNextPage(tableSettings) };
        break;

      case 'orderSearch':
        index = [tableName, 'rows'];
        formName = billingId ? 'searchOrdersOrg' : 'searchOrders';
        rows = state.app.searchResults.getIn(index);
        updateAction = getOrderSearchPending;
        actionPayload = { billingId, page: getNextPage(tableSettings), ...state.form[formName].values };
        break;

      case 'allTransactions':
        index = ['accountTransactions', billingId, 'allTransactions', 'rows'];
        account = getBillingAccountById(billingId, state.app.getIn(['currentUser', 'paymentMethods', 'billingAccs']).toJS());
        rows = state.app.getIn(index);
        updateAction = getAccountTransactionsPending;
        actionPayload = { id: billingId, page: getNextPage(tableSettings), accountNumber: account && account.accountNumber, fromDate, toDate, tableName };
        break;

      case 'openInvoices':
        index = ['accountTransactions', billingId, 'openInvoices', 'rows'];
        account = getBillingAccountById(billingId, state.app.getIn(['currentUser', 'paymentMethods', 'billingAccs']).toJS());
        rows = state.app.getIn(index);
        updateTableOnly = true;
        break;

      case 'creditTransactions':
        index = ['accountTransactions', billingId, 'creditTransactions', 'rows'];
        account = getBillingAccountById(billingId, state.app.getIn(['currentUser', 'paymentMethods', 'billingAccs']).toJS());
        rows = state.app.getIn(index);
        updateTableOnly = true;
        break;

      default:
        return false;
    }

    if (updateTableOnly || !shouldFetchResults(rows, tableSettings)) return false;

    dispatch(updateAction(actionPayload));
    done();
  }
});

export default [
  onInitColumnsLogic,
  appendRowDataLogic
];
